<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />
    <h2 class="text-h5 mx-2 mt-4" style="font-weight: bold">
      {{ generateTitle() }}
    </h2>

    <p class="text-caption text-black mt-2 ma-2">
      {{ generatePrefectureDesc() }}
    </p>

    <v-sheet color="" class="ma-6">
      <v-row :key="viewKey">
        <v-chip-group>
          <v-chip
            v-for="(item, index) in searchPrefectureChip"
            :key="index"
            :closable="item.id != 0"
            @click="closeFilterChips('prefecture_v2')"
            @click:close="closeFilterChips('prefecture_v2')"
          >
            {{ item.code ? $t("prefecture." + item.code) : item.name }}
          </v-chip>
          <v-chip
            v-for="(item, index) in searchCityChip"
            :key="index"
            :closable="item.id != 0"
            @click="closeFilterChips('city_id')"
            @click:close="closeFilterChips('city_id')"
          >
            {{ item.code ? $t("city." + item.code) : item.name }}
          </v-chip>

          <v-chip
            closable
            v-for="(string, index) in sortByChip"
            :key="index"
            @click="closeSortChip()"
            @click:close="closeSortChip()"
          >
            {{ $t(string) }}
          </v-chip>

          <!-- <v-chip
            v-if="sort == '-id'"
            closable
            @click="closeSortChip()"
            @click:close="closeSortChip()"
          >
            {{ $t("searchfilter.latest") }}
          </v-chip> -->

          <v-chip
            closable
            @click="closeFilterChips('searchKeyword')"
            v-for="string in searchChip"
            :key="string"
            @click:close="closeFilterChips('searchKeyword')"
          >
            {{ string }}
          </v-chip>
          <v-chip
            closable
            v-for="(string, index) in tagChip"
            :key="index"
            @click="closeFilterChips('selectedTag', index)"
            @click:close="closeFilterChips('selectedTag', index)"
          >
            {{ $t(string) }}
          </v-chip>

          <v-chip
            closable
            v-for="(string, index) in dayChip"
            :key="index"
            @click="closeFilterChips('selectedDay', index)"
            @click:close="closeFilterChips('selectedDay', index)"
          >
            {{ $t(string) }}
          </v-chip>

          <v-chip
            closable
            v-for="string in timeChip"
            :key="string"
            @click="closeFilterChips('selectedTime')"
            @click:close="closeFilterChips('selectedTime')"
          >
            {{ string }}
          </v-chip>

          <v-chip
            closable
            v-for="(string, index) in menuTypeChip"
            :key="index"
            @click="closeFilterChips('selectedMenuType', index)"
            @click:close="closeFilterChips('selectedMenuType', index)"
          >
            {{ $t(string) }}
          </v-chip>

          <v-chip
            closable
            v-for="(string, index) in priceChip"
            :key="index"
            @click="closeFilterChips('priceRange')"
            @click:close="closeFilterChips('priceRange')"
          >
            {{ string }}
          </v-chip>

          <v-chip
            closable
            v-for="(string, index) in crowdfundingChip"
            :key="index"
            @click="closeFilterChips('isCrowdfunding')"
            @click:close="closeFilterChips('isCrowdfunding')"
          >
            {{ $t(string) }}
          </v-chip>

          <v-chip
            v-if="isOfficial"
            closable
            @click="closeFilterChips('isOfficial')"
            @click:close="closeFilterChips('isOfficial')"
          >
            {{ $t("searchfilter.isOfficial") }}
          </v-chip>
        </v-chip-group>
      </v-row>
    </v-sheet>

    <v-row class="mx-4 mt-5" no-gutters warp>
      <p class="text-body-2 text-black mt-8" style="font-weight: normal">
        <v-icon
          icon="mdi-magnify"
          color="black"
          size="16px"
          class="mb-0"
        ></v-icon>
        {{ $t("searchresults.search_results") }}:{{ totalResult }}
        {{ currentDate() }}
      </p>

      <v-spacer></v-spacer>

      <v-btn
        size="small"
        stacked
        :ripple="false"
        color="black"
        variant="text"
        prepend-icon="mdi-tune-variant"
        @click="openSearchFilterDialog = true"
      >
        {{ $t("searchresults.filter") }}</v-btn
      >
      <v-btn
        :ripple="false"
        size="small"
        variant="text"
        color="black"
        prepend-icon="mdi-sort"
        stacked
      >
        {{ $t("searchresults.sort") }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-for="(item, index) in sortItems"
              :key="index"
              :value="index"
              @click="sortMenuSelect(index)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </v-row>

    <!-- Card -->
    <v-row no-gutters>
      <v-col v-for="shop in shops" :key="shop" cols="12" md="4">
        <ShopCardWithAI
          :shopObjects="shop"
          @showThumbImage="showThumbImageEmitEvent"
        />
      </v-col>
    </v-row>

    <v-sheet
      class="mb-12 pa-4 ma-4 mt-3"
      rounded
      color="yellow-lighten-5"
      v-if="isMobileDevice.value"
    >
      <h2 class="text-body-1">
        <v-icon icon="mdi-medal" color="info"></v-icon>
        {{ $t("searchresults.footer_book") }}
      </h2>
      <v-divider class="mt-2"></v-divider>
      <p class="mt-2 text-caption" style="font-weight: normal">
        {{ $t("searchresults.footer_bookdesc") }}
      </p>
    </v-sheet>
    <v-sheet v-else class="mb-12 pa-4 ma-4 mt-3" rounded color="grey-lighten-4">
      <p style="font-weight: normal">{{ generateFooterTitle() }}</p>
      <br />
      <h2 style="font-weight: normal">
        {{ $t("searchresults.footer_whysearch") }}
      </h2>
      <v-divider></v-divider>
      <p style="font-weight: normal">
        {{ $t("searchresults.footer_whydesc") }}
      </p>
      <br />
      <h2 style="font-weight: normal">
        {{ $t("searchresults.footer_book") }}
      </h2>
      <v-divider></v-divider>
      <p style="font-weight: normal">
        {{ $t("searchresults.footer_bookdesc") }}
      </p>
    </v-sheet>

    <SearchFilterDialog
      v-if="openSearchFilterDialog"
      :openSearchFilterDialog="openSearchFilterDialog"
      :searchFilterObjects="searchFilterObjects"
      @responseFromSearchFilterDialog="responseFromSearchFilterDialog"
      :prefectureList="prefectureList"
    ></SearchFilterDialog>

    <v-bottom-navigation grow bg-color="white">
      <v-btn
        :ripple="false"
        color="blue"
        variant="text"
        prepend-icon="mdi-tune-variant"
        value="left"
        @click="openSearchFilterDialog = true"
      >
        {{ $t("searchresults.filter") }}
      </v-btn>
      <v-btn
        :ripple="false"
        value="right"
        variant="text"
        color="blue"
        prepend-icon="mdi-sort"
      >
        {{ $t("searchresults.sort") }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-for="(item, index) in sortItems"
              :key="index"
              :value="index"
              @click="sortMenuSelect(index)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </v-bottom-navigation>
  </v-sheet>

  <v-dialog
    v-model="showThumbImage"
    :max-width="isMobileDevice.value ? '90%' : '80%'"
  >
    <Carousel :starting-image="0" :images="shopPictures" />
  </v-dialog>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

  <VSonner position="top-center" />
</template>

<script>
import { useHead } from "@vueuse/head";
import Carousel from "@/uitls/Carousel.vue";
import SearchFilterDialog from "@/components/SearchFilterDialog.vue";
import ShopCardWithAI from "@/components/ShopCardWithAI.vue";
import Constants from "@/uitls/Constants";
import Uitls from "@/uitls/Uitls";
import LanguagePack from "../locales/LanguagePack";
import { VSonner, toast } from "vuetify-sonner";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  components: {
    Carousel,
    SearchFilterDialog,
    ShopCardWithAI,
    VSonner,
    Breadcrumbs,
  },

  i18n: {
    sharedMessages: LanguagePack,
  },

  data() {
    return {
      showThumbImage: false,
      isOfficial: false,
      shops: [],
      sortItems: [
        this.$i18n.tc("searchfilter.latest"),
        this.$i18n.tc("searchfilter.popular"),
        this.$i18n.tc("searchfilter.highestRating"),
      ],

      searchFilterObjects: {
        searchKeyword: "",
        prefecture_v2: 0,
        priceRange: [0, 1000],
        selectedTag: [],
        selectedDay: [],
        selectedTime: null,
        selectedMenuType: [],
        city_id: 0,
        isCrowdfunding: false,
      },

      searchPrefectureChip: [],
      searchChip: [],
      tagChip: [],
      dayChip: [],
      timeChip: [],
      priceChip: [],
      searchCityChip: [],
      menuTypeChip: [],
      crowdfundingChip: [],

      openSearchFilterDialog: false,
      shopPictures: [],
      per_page: 10,
      loadingDialog: true,
      totalResult: "",
      sort: "id",
      prefectureList: [],
      viewKey: 0,
      sortByChip: [],
      breadCrumbsProps: [],
    };
  },

  async mounted() {
    await this.getPrefectureList();
    this.setWindowScroll();

    if (!Uitls.isEmpty(this.$cookies.get("searchresult_per_page"))) {
      this.per_page = parseInt(this.$cookies.get("searchresult_per_page"));
    }
    if (!Uitls.isEmpty(this.$cookies.get("sortType"))) {
      this.sort = this.$cookies.get("sortType");
      if (this.sort == "-is_official") {
        this.isOfficial = true;
      }
    }

    //New load
    if (this.$route.query?.query) {
        
      const filterObject = JSON.parse(this.$route.query.query);

      if (filterObject.searchKeyword) {
        this.searchFilterObjects.searchKeyword = filterObject.searchKeyword;
      }
      if (filterObject.prefecture_v2) {
        this.searchFilterObjects.prefecture_v2 = parseInt(
          filterObject.prefecture_v2
        );
      }
      if (filterObject.priceRange) {
        this.searchFilterObjects.priceRange = filterObject.priceRange;
      }
      if (filterObject.selectedTag) {
        this.searchFilterObjects.selectedTag = filterObject.selectedTag;
      }
      if (filterObject.selectedDay) {
        this.searchFilterObjects.selectedDay = filterObject.selectedDay;
      }
      if (filterObject.selectedTime) {
        this.searchFilterObjects.selectedTime = filterObject.selectedTime;
      }
      if (filterObject.selectedMenuType) {
        this.searchFilterObjects.selectedMenuType =
          filterObject.selectedMenuType;
      }
      if (filterObject.city_id) {
        this.searchFilterObjects.city_id = filterObject.city_id;
      }
      if (filterObject.isCrowdfunding) {
        this.searchFilterObjects.isCrowdfunding = filterObject.isCrowdfunding;
      }
    }
    //Reload 
    else{
      this.setReloadFilterObjects();
    }

    this.$router.replace({
      query: { ...this.simpleQueryPath() },
    });

    await this.searchShopData();
  },

  methods: {
    setMetaInfo() {
      useHead({
        title: this.generateTitle(),
        meta: [
          {
            name: "robots",
            content: "index,follow",
          },
          {
            name: "description",
            content: this.generateFooterTitle(),
          },
          {
            name: "og:type",
            content: "website",
          },
          {
            property: "og:title",
            content: this.generateTitle(),
          },
          {
            property: "og:description",
            content: this.generateFooterTitle(),
          },
          {
            property: "og:url",
            content: "https://beauty-360.com/hairsalon/searchresult",
          },
          {
            property: "og:image",
            content:
              "https://storage.googleapis.com/beauty360-dev/shop/1/2024112022524140.jpg",
          },
          {
            property: "og:image:width",
            content: "375",
          },
          {
            property: "og:image:height",
            content: "196",
          },
        ],
        link: [
        {
          rel: "canonical",
          href: "https://beauty-360.com/hairsalon/searchresult"
        }
      ]
      });
    },

    updateView() {
      this.viewKey += 1;
    },

    generateTitle() {
      let title = "";
      let tag = "";

      if (
        this.searchFilterObjects.selectedTag &&
        this.searchFilterObjects.selectedTag.length > 0
      ) {
        const tagKeyword = this.$i18n.t(
          "" + this.searchFilterObjects.selectedTag[0]
        );
        tag = tagKeyword;
      }

      const locale = this.$i18n?.locale || "en";

      //Prefecture
      if (this.searchFilterObjects.prefecture_v2 == 0) {
        if (locale == "zhCN") {
          title = "在马来西亚" + tag + "的理发店";
        } else if (locale == "ms") {
          title = "Kedai gunting rambut " + tag + " di seluruh Malaysia";
        } else {
          title = tag + " Hair Salons In Malaysia Near Me";
        }
      }
      //AreaOrCity
      else {
        let AreaOrCity = this.getAreaName();

        //City
        if (this.searchFilterObjects.city_id > 0) {
          AreaOrCity = this.$i18n.t("city." + this.searchCityChip[0].code);
        }

        if (locale == "zhCN") {
          title = "位于在" + AreaOrCity + tag + "的理发店";
        } else if (locale == "ms") {
          title = "Kedai gunting rambut di " + AreaOrCity;
        } else {
          title = tag + " Hair Salons In " + AreaOrCity + " Near Me";
        }
      }

      return title;
    },

    generatePrefectureDesc() {
      return (
        this.$i18n.t("searchresults.header_this_page_is") +
        this.totalResult +
        " " +
        this.generateTitle() +
        this.$i18n.t("searchresults.near_you_make_you_easy")
      );
    },

    generateFooterTitle() {
      return (
        this.$i18n.t("searchresults.footer_this_page_is") +
        this.generateTitle() +
        this.$i18n.t("searchresults.footer_the_number")
      );
    },

    getAreaName() {
   let tmp = "";
   if (this.searchFilterObjects.prefecture_v2 == 0) {
      tmp = "Malaysia";
   } else {
      tmp = this.$i18n?.t(
         Uitls.prefectureCodeToString(this.searchFilterObjects.prefecture_v2)
      ) || ""; 
   }
   return tmp;
},

    currentDate() {
      const current = new Date();
      const date = `(${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()})`;
      return date;
    },

    setReloadFilterObjects() {
      try {
        // Get the current page URL
        const urlObj = new URL(window.location.href);
        // console.log("urlObj : " + urlObj);

        // Extract and set query parameters with defaults
        const keyword = urlObj.searchParams.get("keyword") || "";
        const prefecture_v2 = urlObj.searchParams.get("prefecture_v2") || 0;
        const priceArray = (urlObj.searchParams.get("price") || "0,1000").split(
          ","
        );
        const tagArray = urlObj.searchParams.get("tag")
          ? urlObj.searchParams.get("tag").split(",")
          : [];
        const operation_day = urlObj.searchParams.getAll("operation_day");
        const menu_type = urlObj.searchParams.getAll("menu_type");
        const time = urlObj.searchParams.get("time") || null;
        const city_id = urlObj.searchParams.get("city_id") || 0;
        const is_crowdfunding = urlObj.searchParams.get("is_crowdfunding") || 0;

        // Set the search filter object
        this.searchFilterObjects = {
          searchKeyword: keyword,
          prefecture_v2: parseInt(prefecture_v2, 10),
          priceRange: priceArray.map(Number),
          selectedTag: tagArray,
          selectedDay: operation_day,
          selectedMenuType: menu_type,
          selectedTime: time,
          city_id: parseInt(city_id, 10),
          isCrowdfunding: parseInt(is_crowdfunding, 10),
        };
      } catch (error) {
        console.log("Error parsing URL or setting filters:", error);
      }
    },
    setWindowScroll() {
      window.onscroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        let bottomOfWindow = scrollPosition >= document.body.offsetHeight - 10;

        if (
          this.shops &&
          this.shops.length != this.totalResult &&
          bottomOfWindow &&
          !this.loadingDialog
        ) {
          this.per_page += 10;
          this.$cookies.set("searchresult_per_page", this.per_page, 60 + 60);
          this.searchShopData(false);
        }
      };
    },

    async searchShopData(resetShops = true) {
      this.loadingDialog = true;

      if (resetShops) {
        this.per_page = 10;
        this.$cookies.remove("searchresult_per_page");
        this.breadCrumbsProps = [];
        window.scrollTo(0, 0);
      }

      await this.handleFilterChips();
      await this.handleBreadcrumbs();

      this.$router.replace({
          query: { ...this.simpleQueryPath() },
        });

      await this.$store
        .dispatch("shopStore/searchShopKeyword", this.simpleQueryPath())
        .then((response) => {
          this.shops = [];
          this.totalResult = 0;
          if (response) {
            if (response.total) {
              this.totalResult = response.total;
            }
            if (response.data) {
              response.data.forEach((shop, i) => {
                if (shop.status) {
                  var shop_img_small = Constants.NO_IMAGE;
                  if (
                    !Uitls.isEmpty(shop.pictures[0]) &&
                    !Uitls.isEmpty(shop.pictures[0].picture_url_small)
                  ) {
                    shop_img_small = shop.pictures[0].picture_url_small;
                  }
                  this.shops.push(Uitls.setShopDetails(shop, shop_img_small));
                } else {
                  //Have hidden shop and stop for endless loop
                  this.totalResult--;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        });

      this.setMetaInfo();
    },

    async handleBreadcrumbs() {
      const vm = this;

      vm.breadCrumbsProps = {
        data: [
          {
            name: "mypage.topPage",
            to: "/",
            disabled: false,
          },
          {
            name: "utls.hair_salon",
            to:
              "/hairsalon/searchresult?query=" +
              encodeURIComponent(
                JSON.stringify({
                  searchKeyword: null,
                  prefecture_v2: 0,
                  priceRange: [0, 1000],
                })
              ),
            disabled: false,
          },
        ],
      };

      if (
        vm.searchFilterObjects.prefecture_v2 >= 0 &&
        vm.prefectureList.length > 0
      ) {
        const prefectureData = vm.prefectureList.find(
          (v) => v.id == vm.searchFilterObjects.prefecture_v2
        );
        if (prefectureData.id > 0) {
          vm.breadCrumbsProps.data.push({
            name: "prefecture." + prefectureData.code,
            to:
              "/hairsalon/searchresult?query=" +
              encodeURIComponent(
                JSON.stringify({
                  searchKeyword: null,
                  prefecture_v2: prefectureData.id,
                  priceRange: [0, 1000],
                })
              ),
            disabled: false,
          });
        }

        if (
          vm.searchFilterObjects.city_id > 0 &&
          prefectureData.cityList &&
          prefectureData.cityList.length > 0
        ) {
          const targetCity = prefectureData.cityList.find(
            (item) => item.id == vm.searchFilterObjects.city_id
          );
          if (targetCity) {
            vm.breadCrumbsProps.data.push({
              name: "city." + targetCity.code,
              to: null,
              disabled: false,
            });
          }
        }
      }
    },

    async handleFilterChips() {
      const vm = this;

      vm.searchChip = [];
      vm.searchPrefectureChip = [];
      vm.tagChip = [];
      vm.dayChip = [];
      vm.priceChip = [];
      vm.timeChip = [];
      vm.searchCityChip = [];
      vm.menuTypeChip = [];
      vm.crowdfundingChip = [];

      if (vm.searchFilterObjects) {
        if (
          vm.searchFilterObjects.searchKeyword &&
          vm.searchFilterObjects.searchKeyword != ""
        ) {
          vm.searchChip = [vm.searchFilterObjects.searchKeyword];
        }
        if (
          vm.searchFilterObjects.prefecture_v2 >= 0 &&
          vm.prefectureList.length > 0
        ) {
          const prefectureData = vm.prefectureList.find(
            (v) => v.id == vm.searchFilterObjects.prefecture_v2
          );
          if (prefectureData) {
            vm.searchPrefectureChip = [prefectureData];
            if (
              vm.searchFilterObjects.city_id > 0 &&
              prefectureData.cityList &&
              prefectureData.cityList.length > 0
            ) {
              const targetCity = prefectureData.cityList.find(
                (item) => item.id == vm.searchFilterObjects.city_id
              );
              if (targetCity) {
                vm.searchCityChip = [targetCity];
              } else {
                vm.searchFilterObjects.city_id = 0;
              }
            } else {
              vm.searchFilterObjects.city_id = 0;
            }
          } else {
            vm.searchFilterObjects.prefecture_v2 = 0;
          }
        }
        if (vm.applyPriceFilter()) {
          vm.priceChip = [
            `RM${vm.searchFilterObjects.priceRange[0]} ~ RM${vm.searchFilterObjects.priceRange[1]}`,
          ];
        }

        if (
          vm.searchFilterObjects.selectedTag &&
          vm.searchFilterObjects.selectedTag.length > 0
        ) {
          vm.tagChip = vm.searchFilterObjects.selectedTag;
        }

        if (
          vm.searchFilterObjects.selectedDay &&
          vm.searchFilterObjects.selectedDay.length > 0
        ) {
          vm.searchFilterObjects.selectedDay.forEach((item) => {
            switch (Number(item)) {
              case 1:
                vm.dayChip.push("shop.mon");
                break;
              case 2:
                vm.dayChip.push("shop.tue");
                break;
              case 3:
                vm.dayChip.push("shop.wed");
                break;
              case 4:
                vm.dayChip.push("shop.thu");
                break;
              case 5:
                vm.dayChip.push("shop.fri");
                break;
              case 6:
                vm.dayChip.push("shop.sat");
                break;
              case 0:
                vm.dayChip.push("shop.sun");
                break;
            }
          });
        }

        if (
          vm.searchFilterObjects.selectedMenuType &&
          vm.searchFilterObjects.selectedMenuType.length > 0
        ) {
          vm.searchFilterObjects.selectedMenuType.forEach((item) => {
            switch (item) {
              case "HAIRCUT":
                vm.menuTypeChip.push("shopprice.cut");
                break;
              case "COLOR":
                vm.menuTypeChip.push("shopprice.color");
                break;
              case "PERM":
                vm.menuTypeChip.push("shopprice.prem");
                break;
              case "TREATMENT":
                vm.menuTypeChip.push("shopprice.treatment");
                break;
              case "REBONDING / RELAXING":
                vm.menuTypeChip.push("shopprice.rebond_relax");
                break;
              case "HIGHLIGHTS":
                vm.menuTypeChip.push("shopprice.highligh");
                break;
              case "CARE BLEACH":
                vm.menuTypeChip.push("shopprice.bleach");
                break;
              case "Others":
                vm.menuTypeChip.push("shopprice.other");
                break;
            }
          });
        }

        if (vm.searchFilterObjects.selectedTime) {
          vm.timeChip = [vm.searchFilterObjects.selectedTime];
        }

        if (vm.searchFilterObjects.isCrowdfunding) {
          vm.crowdfundingChip = [vm.$i18n.t("searchfilter.is_crowdfunding")];
        }
      }
    },

    handleTagForApi() {
      let result = [];
      if (this.searchFilterObjects.selectedTag.length > 0) {
        for (let i = 0; i < this.searchFilterObjects.selectedTag.length; i++) {
          const element = this.searchFilterObjects.selectedTag[i];
          const tagKeyword = this.$i18n.t("" + element, "en");
          result.push(tagKeyword);
        }
      }
      return result.length > 0 ? result.join(",") : null;
    },

    applyPriceFilter() {
      const vm = this;
      let defaultValue = false;
      if (
        vm.searchFilterObjects.priceRange &&
        vm.searchFilterObjects.priceRange.length > 0 &&
        vm.searchFilterObjects.priceRange[0] &&
        vm.searchFilterObjects.priceRange[1] &&
        (vm.searchFilterObjects.priceRange[0] != 0 ||
          vm.searchFilterObjects.priceRange[1] != 1000)
      ) {
        defaultValue = true;
      }

      return defaultValue;
    },

    closeFilterChips(closeType, selectedIndex = null) {
      const vm = this;
      // console.log('closeType', closeType);
      // console.log('selectedIndex', selectedIndex);
      if (vm.searchFilterObjects[closeType] !== undefined) {
        switch (closeType) {
          case "searchKeyword":
            vm.searchFilterObjects.searchKeyword = "";
            break;
          case "prefecture_v2":
            vm.searchFilterObjects.prefecture_v2 = 0;
            break;
          case "priceRange":
            vm.searchFilterObjects.priceRange = [0, 1000];
            break;
          case "selectedTime":
            vm.searchFilterObjects.selectedTime = null;
            break;
          case "selectedTag":
            if (
              selectedIndex >= 0 &&
              vm.searchFilterObjects.selectedTag[selectedIndex]
            ) {
              vm.searchFilterObjects[closeType].splice(selectedIndex, 1);
            } else {
              vm.searchFilterObjects.selectedTag = [];
            }
            break;
          case "selectedDay":
            if (
              selectedIndex >= 0 &&
              vm.searchFilterObjects.selectedDay[selectedIndex]
            ) {
              vm.searchFilterObjects[closeType].splice(selectedIndex, 1);
            } else {
              vm.searchFilterObjects.selectedDay = [];
            }
            break;
          case "selectedMenuType":
            if (
              selectedIndex >= 0 &&
              vm.searchFilterObjects.selectedMenuType[selectedIndex]
            ) {
              vm.searchFilterObjects[closeType].splice(selectedIndex, 1);
            } else {
              vm.searchFilterObjects.selectedMenuType = [];
            }
            break;
          case "city_id":
            vm.searchFilterObjects.city_id = 0;
            break;
          case "isCrowdfunding":
            vm.searchFilterObjects.isCrowdfunding = false;
            break;
        }
      }

      if (closeType == "isOfficial") {
        this.isOfficial = false;
        this.sortMenuSelect(99);
      }

      vm.updateView();
      vm.$forceUpdate();
      vm.searchShopData();
    },

    closeSortChip() {
      this.sortMenuSelect(99);
    },

    showThumbImageEmitEvent(value) {
      const result = this.shops.find(({ shop_id }) => shop_id === value);

      if (result) {
        this.showThumbImage = true;

        this.shopPictures = [];

        for (let i = 0; i < result.shop_img_list.length; i++) {
          this.shopPictures.push({
            id: i,
            big: result.shop_img_list[i].picture_url,
            thumb: result.shop_img_list[i].picture_url_small,
          });
        }
      }
    },

    sortMenuSelect(index) {
      this.isOfficial = false;
      this.searchFilterObjects.isOfficial = this.isOfficial;

      switch (index) {
        case 0:
          this.sort = "-id";
          break;
        case 1:
          this.sort = "-favorite_count";
          break;
        case 2:
          this.sort = "-detail_count";
          break;
        case 99:
          this.sort = "id";
          break;
        default:
          toast(this.$i18n.tc("shop.comingSoon"), {
            cardProps: {
              color: "info",
              class: "my-toast",
            },
          });
          return;
      }

      cookies.set("sortType", this.sort, 60 + 60);

      if (index == 99) {
        this.sortByChip = [];
      } else {
        this.sortByChip = [this.sortItems[index]];
      }

      this.searchShopData();
    },

    responseFromSearchFilterDialog(obj) {
      this.openSearchFilterDialog = false;

      if (obj && obj.newSearchValue == true) {
        this.searchFilterObjects = {
          searchKeyword: obj.searchKeyword,
          prefecture_v2: obj.prefecture_v2,
          priceRange: obj.priceRange,
          selectedTag: obj.selectedTag,
          selectedDay: obj.selectedDay,
          selectedTime: obj.selectedTime,
          selectedMenuType: obj.selectedMenuType,
          city_id: obj.city_id,
          isCrowdfunding: obj.isCrowdfunding,
          isOfficial: obj.isOfficial,
        };

        if (obj.isOfficial) {
          this.isOfficial = true;
          this.sort = "-is_official";
          cookies.set("sortType", "-is_official", 60 + 60);
        }

        this.$router.replace({
          query: { ...this.simpleQueryPath() },
        });

        this.searchShopData();
      }
    },

    simpleQueryPath() {
      return {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        page: 1,
        per_page: this.per_page,
        sort: this.sort,
        keyword: this.searchFilterObjects.searchKeyword,
        prefecture_v2: this.searchFilterObjects.prefecture_v2,
        price: this.applyPriceFilter()
          ? JSON.stringify(this.searchFilterObjects.priceRange)
          : null,
        time: this.searchFilterObjects.selectedTime,
        tag: this.handleTagForApi(),
        operation_day: this.searchFilterObjects.selectedDay,
        menu_type: this.searchFilterObjects.selectedMenuType,
        city_id: this.searchFilterObjects.city_id,
        is_crowdfunding: this.searchFilterObjects.isCrowdfunding ? 1 : 0,
      };
    },

    async getPrefectureList() {
      const vm = this;
      vm.prefectureList = [
        {
          id: 0,
          name: "all_area",
          code: "all_area",
          cityList: [],
        },
      ];

      await vm.$store
        .dispatch("shopStore/getPrefectureByCountry", "malaysia")
        .then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            response.data.forEach((v) => {
              vm.prefectureList.push({
                id: v.id,
                name: v.name,
                code: v.code,
                cityList: v.city_list ? v.city_list : [],
              });
            });
            // console.log(vm.prefectureList)
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style scoped>
.v-chip {
  overflow: initial;
  color: #2196f3;
}

.v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
</style>