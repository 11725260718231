<!-- https://webxreal.com/js-vue-vuetify-dialog/? -->
<template>
  <v-row justify="center">
    <v-dialog
      class="ma-0"
      max-width="1200"
      v-model="this.openSearchFilterDialog"
      :fullscreen="isMobileDevice.value"
      :scrim="true"
      transition="dialog-bottom-transition"
    >
      <v-sheet
        class="pa-0 ma-0 mx-auto"
        color="white"
        max-width="1200"
        width="100%"
        elevation="0"
      >
        <v-toolbar dark color="blue" height="48">
          <v-icon class="mx-4">mdi-tune-variant</v-icon>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-4"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            :ripple="false"
            @click="closeDialog"
          ></v-btn>
        </v-toolbar>

        <!-- <v-tabs
          class="mt-0"
          v-model="tab"
          bg-color="clear"
          color="blue"
          center-active
          grow
        >
          <v-tab value="tab-1" :ripple="false"
            ><v-icon start icon="mdi-magnify" />
            <p class="text-subtitle-1">{{ $t("searchfilter.basic") }}</p></v-tab
          >
          <v-tab value="tab-2" :ripple="false"
            ><v-icon start icon="mdi-more" />
            <p class="text-subtitle-1">{{ $t("searchfilter.more") }}</p></v-tab
          >
        </v-tabs> -->

        <v-window v-model="tab" :touch="false">
          <v-window-item value="tab-1">
            <v-text-field
              class="pa-4 mt-2"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              v-model="searchKeyword"
              clearable
              hide-details
              v-if="showKeywordSearch"
            >
              <v-field-label style="font-weight: normal">
                {{ $t("homeview.search_keyword") }}
              </v-field-label>
            </v-text-field>

            <v-select
              style="font-weight: normal"
              prepend-inner-icon="mdi-map-marker-outline"
              transition="none"
              class="mt-4 ma-4"
              variant="outlined"
              :label="$t('shop.prefecture')"
              v-model="prefecture_v2"
              :items="prefectureList"
              :item-title="
                (v) => (v.code ? $t('prefecture.' + v.code) : v.name)
              "
              item-value="id"
              @update:modelValue="updateCityList"
              v-if="showPrefectureSearch"
            ></v-select>

            <div class="mt-n8" v-if="prefecture_v2 > 0 && showCitySearch">
              <v-row no-gutters warp>
                <v-col cols="1">
                  <v-icon class="mx-4">mdi-size-l</v-icon>
                </v-col>
                <v-col cols="11">
                  <v-select
                    style="font-weight: normal"
                    prepend-inner-icon="mdi-map-marker-outline"
                    transition="none"
                    class="mt-4 ma-4"
                    variant="outlined"
                    :label="$t('shop.city')"
                    v-model="city_id"
                    :items="cityList"
                    :item-title="
                      (v) => (v.code ? $t('city.' + v.code) : v.name)
                    "
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </div>

            <v-select
              prepend-inner-icon="mdi-pound"
              transition="none"
              class="mt-4 ma-4"
              :label="$t('searchfilter.tag')"
              :items="beautyServiceTags"
              :item-title="(beautyServiceTags) => $t(beautyServiceTags)"
              variant="outlined"
              chips
              multiple
              v-model="selectedTag"
              return-object
              v-if="showTagSearch"
            ></v-select>

            <v-row class="mx-1">
              <v-col cols="12" class="mt-n4">
                <v-checkbox v-model="isCrowdfunding" hide-details color="blue">
                  <template v-slot:label>
                    <v-img src="/discounts.png" :width="24"></v-img>
                    <p style="font-weight: normal" class="mx-2">
                      {{ $t("searchfilter.is_crowdfunding") }}
                    </p>
                  </template></v-checkbox
                >
              </v-col>

              <v-col cols="12" class="mt-n4">
                <v-checkbox v-model="isOfficial" hide-details color="blue">
                  <template v-slot:label>
                    <v-icon
                        icon="mdi-check-decagram"
                        size="24"
                        color="blue"
                        class="mt-n0"
                      />
                    <p style="font-weight: normal" class="mx-2">
                      {{ $t("searchfilter.isOfficial") }}
                    </p>
                  </template></v-checkbox
                >
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="ma-1 mt-0">
              <v-col cols="12" class="text-subtitle-2 text-grey-darken-3">
                <p style="font-weight: normal">
                  {{ $t("searchfilter.available_date") }}
                </p>

                <v-chip-group
                  multiple
                  selected-class="text-blue"
                  column
                  v-model="selectedDay"
                >
                  <v-chip
                    class="text-body-1"
                    style="font-weight: bold"
                    v-for="(chip, index) in day_chips"
                    :key="index"
                    :text="chip.text"
                    variant="outlined"
                    filter
                    :value="chip.value"
                  ></v-chip>
                </v-chip-group>
              </v-col>
            </v-row>

            <v-select
              class="mt-0 ma-4"
              prepend-inner-icon="mdi-clock-time-eight-outline"
              transition="none"
              :label="$t('searchfilter.available_time')"
              :items="timeList"
              variant="outlined"
              v-model="selectedTime"
              v-if="showTimeSearch"
            ></v-select>

            <!-- <v-row dense>
              <v-col cols="6">
                <v-text-field
                  class="mt-6 ma-4"
                  v-model="trip.start"
                  label="Available Date"
                  type="date"
                  variant="underlined"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  transition="none"
                  class="mt-6 ma-4"
                  :label="$t('searchfilter.available_time')"
                  :items="timeList"
                  variant="underlined"
                  v-model="selectedTime"
                ></v-select>
              </v-col>
            </v-row> -->

            <!-- <v-row dense>
              <v-col cols="6">
                <v-select
                  transition="none"
                  class="mt-6 ma-4"
                  label="Min Price(RM)"
                  :items="priceList"
                  variant="underlined"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  transition="none"
                  class="mt-6 ma-4"
                  label="Max Price(RM)"
                  :items="priceList"
                  variant="underlined"
                ></v-select>
              </v-col>
            </v-row> -->

            <v-divider></v-divider>

            <v-row class="ma-1 mt-0">
              <v-col cols="12" class="text-subtitle-2 text-grey-darken-3">
                <p style="font-weight: normal">{{ $t("searchfilter.menu") }}</p>
                <span class="text-caption text-grey">{{
                  $t("searchfilter.some_shop_not_show")
                }}</span>

                <v-chip-group
                  multiple
                  selected-class="text-blue"
                  column
                  v-model="selectedMenuType"
                >
                  <v-chip
                    class="text-body-1"
                    style="font-weight: bold"
                    v-for="(chip, index) in menu_chips"
                    :key="index"
                    :text="chip.text"
                    variant="outlined"
                    filter
                    :value="chip.value"
                  ></v-chip>
                </v-chip-group>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="ma-1 mb-12">
              <v-col cols="12" class="text-subtitle-2 text-grey-darken-3">
                <p style="font-weight: normal">
                  {{ $t("searchfilter.price_range") }}
                </p>
                <span class="text-caption text-grey">{{
                  $t("searchfilter.some_shop_not_show")
                }}</span>
                <p
                  class="text-subtitle-1"
                  v-if="priceRange[0] > 0 || priceRange[1] < 1000"
                >
                  RM{{ priceRange[0] }} - RM{{ priceRange[1] }}
                </p>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-range-slider
                  color="blue"
                  class="align-center"
                  :max="1000"
                  :min="0"
                  :step="10"
                  v-model="priceRange"
                  thumb-label="always"
                ></v-range-slider>
              </v-col>
            </v-row>
            <div class="mt-3">.</div>
          </v-window-item>

          <v-window-item value="tab-2">
            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="Gender"
              :items="genderTags"
              variant="underlined"
            ></v-select>

            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="Hair Issue"
              :items="issueTags"
              variant="underlined"
            ></v-select>

            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="Hair Style"
              :items="hairLenghtTags"
              variant="underlined"
            ></v-select>

            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="Tags"
              :items="otherTags"
              variant="underlined"
            ></v-select>

            <v-select
              transition="none"
              class="mt-6 ma-4"
              label="TrainStation"
              :items="prefectureList"
              variant="underlined"
            ></v-select>
          </v-window-item>
        </v-window>

        <v-row justify="center" no-gutters>
          <v-col
            style="position: fixed; bottom: 0; right: 2; background: clear"
            cols="12"
          >
            <v-container>
              <v-btn
                prepend-icon="mdi-magnify"
                elevation="4"
                color="blue"
                width="100%"
                height="45px"
                :ripple="false"
                rounded="pill"
                @click="startSearchOnClick"
              >
                {{ $t("searchfilter.search") }}
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script>
import Uitls from "@/uitls/Uitls";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  props: {
    openSearchFilterDialog: false,

    searchFilterObjects: {
      type: Object,
      default: () => ({}),
    },
    prefectureList: {
      type: Array,
      default: () => [],
    },

    showKeywordSearch: {
      type: Boolean,
      default: true,
    },
    showPrefectureSearch: {
      type: Boolean,
      default: true,
    },
    showCitySearch: {
      type: Boolean,
      default: true,
    },
    showTagSearch: {
      type: Boolean,
      default: true,
    },
    showTimeSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      prefectureListLoaded: false,
      searchKeyword: "",
      priceRange: [50, 300],
      tab: null,
      selectedTag: [],
      selectedDay: [],
      selectedMenuType: [],
      timeList: Uitls.timeList(),
      beautyServiceTags: Uitls.GPTSummaryTags(),
      selectedTime: "",
      prefecture_v2: 0,
      isCrowdfunding: false,
      isOfficial: false,

      trip: {
        name: "",
        location: null,
        start: null,
        end: null,
      },
      genderTags: ["All", "Male", "Female", "Kids"],
      priceList: [
        "Any",
        "RM10",
        "RM20",
        "RM30",
        "RM40",
        "RM50",
        "RM60",
        "RM70",
        "RM80",
        "RM90",
        "RM100",
        "RM200",
        "RM300",
        "RM400",
        "RM500",
      ],
      issueTags: ["All", "Frizzy Hair", "Dry Hair", "Thinning Hair"],
      hairLenghtTags: [
        "All",
        "Very Short",
        "Short",
        "Medium",
        "Semi Long",
        "Long",
      ],
      otherTags: [
        "All",
        "Luxury",
        "Free cut Model",
        "SNS(TikTik, Instagram)",
        "Low Budget",
        "Japan Style",
        "Korea Style",
        "Family Friendly",
        "Islam Friendly",
        "Discount",
      ],

      city_id: 0,
      cityList: [
        {
          id: 0,
          name: "all_city",
          code: "all_city",
        },
      ],

      day_chips: [
        { text: this.$i18n.t("shop.mon"), value: 1 },
        { text: this.$i18n.t("shop.tue"), value: 2 },
        { text: this.$i18n.t("shop.wed"), value: 3 },
        { text: this.$i18n.t("shop.thu"), value: 4 },
        { text: this.$i18n.t("shop.fri"), value: 5 },
        { text: this.$i18n.t("shop.sat"), value: 6 },
        { text: this.$i18n.t("shop.sun"), value: 0 },
      ],
      menu_chips: [
        { text: this.$i18n.t("shopprice.cut"), value: "HAIRCUT" },
        { text: this.$i18n.t("shopprice.color"), value: "COLOR" },
        { text: this.$i18n.t("shopprice.prem"), value: "PERM" },
        { text: this.$i18n.t("shopprice.treatment"), value: "TREATMENT" },
        {
          text: this.$i18n.t("shopprice.rebond_relax"),
          value: "REBONDING / RELAXING",
        },
        { text: this.$i18n.t("shopprice.highligh"), value: "HIGHLIGHTS" },
        { text: this.$i18n.t("shopprice.bleach"), value: "CARE BLEACH" },
        { text: this.$i18n.t("shopprice.other"), value: "Others" },
      ],
    };
  },

  mounted() {
    this.searchKeyword = this.searchFilterObjects.searchKeyword;
    this.selectedTag = this.searchFilterObjects.selectedTag;
    this.selectedDay = this.searchFilterObjects.selectedDay;
    this.selectedMenuType = this.searchFilterObjects.selectedMenuType;
    this.selectedTime = this.searchFilterObjects.selectedTime;
    this.priceRange = this.searchFilterObjects.priceRange;
    this.prefecture_v2 = this.searchFilterObjects.prefecture_v2;
    this.city_id = this.searchFilterObjects.city_id;
    this.isCrowdfunding = this.searchFilterObjects.isCrowdfunding;
    this.isOfficial = this.searchFilterObjects.isOfficial;
    this.updateCityList();
  },
  methods: {
    closeDialog() {
      this.$emit("responseFromSearchFilterDialog", { newSearchValue: false });
    },

    startSearchOnClick() {
      if (this.isOfficial) {
        cookies.set("sortType", "-is_official", 60 + 60);
      }

      this.$emit("responseFromSearchFilterDialog", {
        newSearchValue: true,
        searchKeyword: this.searchKeyword,
        selectedTag: this.selectedTag,
        selectedDay: this.selectedDay,
        selectedMenuType: this.selectedMenuType,
        selectedTime: this.selectedTime,
        priceRange: this.priceRange,
        prefecture_v2: this.prefecture_v2,
        city_id: this.city_id,
        isCrowdfunding: this.isCrowdfunding,
        isOfficial: this.isOfficial,
      });
    },

    updateCityList() {
      const vm = this;
      vm.cityList = [
        {
          id: 0,
          name: "all_city",
          code: "all_city",
        },
      ];
      if (vm.prefecture_v2 > 0) {
        const targetPrefecture = vm.prefectureList.find(
          (item) => item.id == vm.prefecture_v2
        );
        if (targetPrefecture && targetPrefecture.cityList) {
          vm.cityList = JSON.parse(JSON.stringify(targetPrefecture.cityList));
          vm.cityList.unshift({
            id: 0,
            name: "all_city",
            code: "all_city",
          });
        }

        if (vm.city_id > 0) {
          const targetCity = vm.cityList.find((item) => item.id == vm.city_id);
          if (!targetCity) {
            vm.city_id = 0;
          }
        }
      }
    },
  },
};
</script>

