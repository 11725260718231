<template>
  <v-footer color="grey-lighten-5">
    <v-row justify="center" no-gutters>
      <v-btn
        class="text-none mr-4"
        variant="plain"
        rounded="s"
        color="black"
        :to="{ name: 'HomeView' }"
      >
        <p style="font-weight: normal">
          {{ $t("mypage.topPage") }}
        </p></v-btn
      >

      <v-btn
        class="text-none mr-4"
        variant="plain"
        rounded="s"
        color="black"
        :to="{ name: 'BusinessPage' }"
      >
        <p style="font-weight: normal">
          {{ $t("busienssPage.forBusiness") }}
        </p></v-btn
      >

      <v-btn
        class="text-none mr-4"
        variant="plain"
        rounded="s"
        color="black"
        :to="{
          name: 'ContactUs',
          params: { type: '1' },
        }"
      >
        <p style="font-weight: normal">
          {{ $t("shop.contact_us") }}
        </p></v-btn
      >

      <v-btn
        variant="plain"
        href="https://www.facebook.com/people/Beauty-360/61561573182423/"
        target="_blank"
        rel="noopener"
      >
        <v-img src="/facebook_icon.png" :width="20" aspect-ratio="1/1"></v-img
      ></v-btn>

      <v-btn
        variant="plain"
        href="https://www.instagram.com/beauty360.official/"
        target="_blank"
        rel="noopener"
      >
        <v-img src="/instagram_icon.png" :width="20" aspect-ratio="1/1"></v-img
      ></v-btn>

      <v-btn
        variant="plain"
        href="https://www.xiaohongshu.com/user/profile/665dafd100000000030311a7"
        target="_blank"
        rel="noopener"
      >
        <v-img
          src="/Xiaohongshu_icon.png"
          :width="20"
          aspect-ratio="1/1"
        ></v-img
      ></v-btn>

      <v-col class="text-center mt-4" cols="12">
        © {{ new Date().getFullYear() }} —
        <strong>Beauty 360™. All rights reserved.</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  background: grey-lighten-4;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  padding: 0 30px 20px;
}
</style>