export default {

    en: {
        message: {
            hi: "Hi there!",
            find_your_salon: "Discover Malaysia's best beauty salon now on Beauty 360",
            inspire_your_salon: "Our friendly AI is here to make the selection easier for you.",
            share_desc: "Your friends like to share you a shop information, goto check it now.",
            last_search: "Last search",
            search: "Search Now",
            new_search: "Find more",
            category: "Category",
            map: "Map",
            shop: "Shop",
            area: "Area",
            low_budget: "Low Budget",
            luxury: "Luxury",
            popular: "Popular",
            islam_friendly: "Islam Friendly",
            discount: "Discount",
            promotion: "Promotion",
            
            
            
            name: "Name",

        }
    },


    zhCN: {
        message: {
            hi: "你好!",
            find_your_salon : "立即搜索在马来西亚适合您的美容院吧",
            inspire_your_salon: "Inspire Hair, Beauty, Nails and more",
            share_desc: "Your friends like to share you a shop information, goto check it now.",
            last_search: "上次搜索",
            search: "搜索",
            new_search: "新搜索",
            category: "种类",
            map: "地图",
            shop: "店铺",
            area: "区域",
            low_budget: "低预算",
            luxury: "高级",
            popular: "人气",
            islam_friendly: "回教徒便利",
            discount: "优惠",
            promotion: "特价",
            
            
            
            
            name: "姓名",
            
        }


       
    }
}