<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <Breadcrumbs
      :breadCrumbsProps="breadCrumbsProps"
      @breadcrumb-click="handleBreadcrumbClick"
    />

    <!-- AreaCard -->
    <AreaCards
      v-if="!showCityList"
      @callBackFromAreaCard="callBackFromAreaCard"
    />

    <v-list v-if="showCityList">
      <template v-for="(city, index) in cityList" :key="city.code">
        <v-list-item :value="city.code" @click="selectCity(city)">
          <v-list-item>
            <v-list-item-title>
              {{ city.code ? $t("city." + city.code) : city.name }}
              <v-icon class="mr-2">mdi-chevron-right</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list-item>
        <v-divider v-if="index < cityList.length - 1" />
      </template>
    </v-list>
  </v-sheet>
</template>
  
  <script>
import AreaCards from "@/components/AreaCards.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  components: {
    AreaCards,
    Breadcrumbs,
  },

  async created() {
    await this.getPrefectureList();
    // await this.updateCityList();
  },

  data() {
    return {
      breadCrumbsProps: {
        data: [
          { name: "mypage.topPage", to: "/" },
          { name: "loginpage.select_area", to: "/selectarea" },
        ],
      },

      prefectureList: [],
      showCityList: false,
      selectedPrefecture: null,
      selectedCity: null,
      cityList: [
        {
          id: 0,
          name: "all_city",
          code: "all_city",
        },
      ],
    };
  },

  methods: {
    async getPrefectureList() {
      const vm = this;
      vm.prefectureList = [
        {
          id: 0,
          name: "all_area",
          code: "all_area",
        },
      ];

      await vm.$store
        .dispatch("shopStore/getPrefectureByCountry", "malaysia")
        .then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            response.data.forEach((v) => {
              vm.prefectureList.push({
                id: v.id,
                name: v.name,
                code: v.code,
                cityList: v.city_list ? v.city_list : [],
              });
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          vm.isLoadedPrefecture = true;
        });
    },

    updateCityList(selectAre) {
      const vm = this;

      vm.showCityList = true;

      const targetPrefecture = vm.prefectureList.find(
        (item) => item.id == selectAre.value
      );

      let result = JSON.parse(JSON.stringify(targetPrefecture));
      vm.cityList = result.cityList;
      vm.cityList.unshift({
        id: 0,
        name: "all_city",
        code: "all_city",
      });
    },

    selectCity(city) {
      this.selectedCity = city;
      console.log("選択された都市:", city);

      const obj = {
        newSearchValue: true,
        searchKeyword: "",
        // selectedTag: this.selectedTag,
        // selectedDay: this.selectedDay,
        // selectedMenuType: this.selectedMenuType,
        // selectedTime: this.selectedTime,
        // priceRange: this.priceRange,
        prefecture_v2: this.selectedPrefecture.value,
        city_id: city.id,
        isCrowdfunding: true,
      };

      this.$router.push({
        name: "SearchResult",
        query: {
          query: JSON.stringify(obj),
        },
      });
    },

    handleBreadcrumbClick(to) {
      if (to === "/selectarea") {
        this.showCityList = false;
        location.reload();
      }
    },

    callBackFromAreaCard(item) {
      this.selectedPrefecture = item;
      const areaText = item.title.replace("prefecture.", "");

      this.breadCrumbsProps = {
        data: [
          { name: "mypage.topPage", to: "/" },
          { name: "loginpage.select_area", to: "/selectarea" },
          { name: "prefecture." + areaText },
        ],
      };
      this.updateCityList(item);
    },
  },
};
</script>
  
<style>
</style>