<template>
  <v-sheet class="pa-0 ma-0 mx-auto" color="white" max-width="1200" rounded="lg" width="100%" elevation="0">
    <v-container v-if="item">
      <p class="text-body-2 text-grey text-right">
        {{ extractYearAndMonth(item.date) }}
      </p>
      <v-img class="bg-white" max-height="512px" width="auto" :src="item.better_featured_image ? item.better_featured_image.source_url : no_IMAGE" :lazy-src="no_IMAGE" />
      <div align="right" class="mt-0">
        <v-btn-toggle class="ma-2" v-model="photo_position" rounded="lg" color="blue" group divided variant="outlined">
          <v-btn icon="mdi-tray-arrow-up" :ripple="false" @click="snsShare" elevation="0" size="small">
          </v-btn>
        </v-btn-toggle>
      </div>

      <h1 class="text-h5">{{ item.title.rendered }}</h1>
      <br />

      <p v-html="item.content.rendered" ref="vHtmlElement" style="font-weight: normal"></p>

      <v-sheet v-if="tagss.length > 0" class="mt-6">
        <v-chip-group v-model="chipSelect">
          <v-chip v-for="item in tagss" :key="item" variant="text" @click="chipClick(item)">
           <p style="font-weight: normal"> #{{ item }}</p>
          </v-chip>
        </v-chip-group>
      </v-sheet>

      <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" v-if="articleCategory" class="mt-4" />
    </v-container>

    <SNSShareArticleDialog v-if="item !== null" :articleObjects="item" :openSNSShareDialog="openSNSShareDialog"
      @result="responseFromSNSDialog">
    </SNSShareArticleDialog>

    <!-- Relative Article -->
    <v-container>
      <p class="text-h5 mx-2" style="font-weight: bold">
        <v-icon icon="mdi-newspaper" color="light-blue" size="24px" class="mb-1"></v-icon>
        {{ $t("homeview.relative_topics") }}
      </p>

      <v-row>
        <v-col v-for="item in relativeTopicsList" :key="item.name" cols="12" md="6" xs="12" sm="12">
          <ArticleTableCellCard :articleObjects="item" @articleIDCallBack="articleIDCallBack"
            @articleDetailOnClickBySlug="articleDetailOnClickBySlug">
          </ArticleTableCellCard>
        </v-col>

        <v-col>
          <div>
            <v-btn width="40%" elevation="0" class="ma-6 border" height="50px" :ripple="false" rounded="pill"
              :to="{ name: 'ArticleViewList' }">
              {{ $t("homeview.show_more") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet class="mt-12">
      <LocationShopList @shopId="callBackFromShopCard" :source="'topic'" :sourceId="article_slug" />
    </v-sheet>
  </v-sheet>
</template>

<script>
import { useHead } from "@vueuse/head";
import Constants from "@/uitls/Constants";
import utils from "@/uitls/Uitls";
import ArticleTableCellCard from "@/components/ArticleTableCellCard.vue";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapState } from "vuex";
import LocationShopList from "@/components/LocationShopList.vue";
import SNSShareArticleDialog from "@/components/SNSShareArticleDialog.vue";

export default {
  props: ["article_slug"],

  components: {
    ArticleTableCellCard,
    Breadcrumbs,
    LocationShopList,
    SNSShareArticleDialog,
  },

  setup() { },

  data() {
    return {
      item: null,
      no_IMAGE: Constants.NO_IMAGE,
      relativeTopicsList: null,
      tagss: [],
      articleCategory: null,
      shopId: null,
      openSNSShareDialog: false,
    };
  },

  async beforeMount() { },

  async mounted() {
    this.loadArticle(this.article_slug);
  },

  computed: {
    ...mapState({
      getArticleCategory: (state) => state.ArticleStore.articleCategiry,
    }),

    breadCrumbsProps: function () {
      return {
        data: [
          { name: "mypage.topPage", to: "/" },
          { name: "homeview.new_topics", to: "/topic" },
          {
            name: this.$i18n.tc("utls." + this.articleCategory),
            to: "/hairsalon/topic/find/" + this.$i18n.tc("utls." + this.articleCategory),
          },
          { name: this.item.title.rendered },
        ],
      };
    },
  },

  methods: {
    resetValue() {
      this.item = null;
      this.relativeTopicsList = null;
      this.tagss = [];
      this.articleCategory = null;
      this.shopId = null;
    },

    resetRouterPrompt(articleSlug) {
      this.$router.replace({
        name: "ArticleViewDetailBySlug",
        params: { article_slug: articleSlug },
      });
    },

    async chipClick(keyword) {
      this.$router.push({
        name: "ArticleSearch",
        params: { keyword: keyword },
      });
    },

    async loadArticle(articleSlug) {
      this.item = null;

      await this.$store.dispatch("ArticleStore/getWordPressSearchArticle", {
        slug: articleSlug,
        page: 1,
        per_page: 100,
        _embed: true,
      }
      ).then((res) => {
        if (res && res.length > 0) {
          this.item = JSON.parse(JSON.stringify(res[0]))
        } else {
          this.$router.push({ name: "HomeView" })
        }
      })

      //関連記事
      if (!utils.isNil(this.item)) {
        this.relativeTopicsList = await this.$store.dispatch(
          "ArticleStore/getWordPressCategoryList",
          {
            categories: this.item.categories[0],
            per_page: 6,
            _embed: true,
          }
        );

        //Tags
        this.tagss = [];
        for (let index = 0; index < this.item.tags.length; index++) {
          const element = this.item.tags[index];
          const tagString = await this.$store.dispatch(
            "ArticleStore/getWordPressSingleTag",
            element
          );
          this.tagss.push(tagString.name);
        }
      }

      this.articleCategory = this.getCategoryName(this.item.categories[0]);
      this.htmlInjection();
      this.setMetaInfo();
    },

    htmlInjection() {

      this.$refs.vHtmlElement?.querySelectorAll("a").forEach((a) => {
        const href = a.getAttribute("href");

        a.addEventListener("click", (event) => {

          if (href.includes("https://media.beauty-360.com")) {
            event.preventDefault();

            const title = href.split("#").pop();
            const element = document.getElementById(title);
            var headerOffset = 45;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        });
      });
    },

    articleIDCallBack(articleID) {
      window.scrollTo(0, 0);

      this.resetRouterPrompt(articleID);
      this.loadArticle(articleID);
    },
    articleDetailOnClickBySlug(articleSlug) {
      window.scrollTo(0, 0);

      this.resetRouterPrompt(articleSlug);
      this.loadArticle(articleSlug);
    },

    setMetaInfo() {
      useHead({
        title:
          this.item.title.rendered +
          " | " +
          this.$i18n.tc("homeview.find_your_salon"),
        meta: [
          {
            name: "robots",
            content: "index,follow",
          },
          {
            name: "description",
            content:
              this.item.title.rendered +
              " | " +
              this.$i18n.tc("homeview.og_desc"),
          },
          {
            name: "keywords",
            content: this.tagss.toString(),
          },
          {
            property: "og:title",
            content:
              this.item.title.rendered +
              " | " +
              this.$i18n.tc("homeview.find_your_salon"),
          },
          {
            property: "og:description",
            content:
              this.item.title.rendered +
              " | " +
              this.$i18n.tc("homeview.og_desc"),
          },
          {
            property: "og:url",
            content: "https://beauty-360.com/topic/" + this.article_slug,
          },
          {
            property: "og:image",
            content: this.item.better_featured_image.source_url,
          },
          {
            property: "og:image:alt",
            content: this.item.title.rendered + " | Beauty 360",
          },
        ],
        link: [
          {
            rel: "canonical",
            content: "https://beauty-360.com/topic/" + this.article_slug,
          },
        ],
      });
    },

    getCategoryName(item) {
      return utils.categoryMappingResult(item, this.getArticleCategory);
    },

    callBackFromShopCard(shopId) {
      this.shopId = shopId;
    },

    snsShare() {
      this.openSNSShareDialog = true;
    },

    responseFromSNSDialog() {
      this.openSNSShareDialog = false;
    },

    extractYearAndMonth(value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
      const day = date.getDate(); // Use getDate() to get the day of the month

      // Format with leading zeros for single-digit day/month
      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    },
  },
};
</script>

<style scoped>
::v-deep figure {
  /* border: 2px solid red; */
  position: relative;
  text-align: center;
}

::v-deep .wp-block-gallery.has-nested-images figure.wp-block-image img {
  width: 90%;
  height: auto;
  max-width: 512px;
  padding-top: 18px;
  /* border: 1px ridge blue; */
  /* position: relative; */
  /* justify-content: center; */
  /* box-sizing: border-box; */
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-grow: 1; */
}

::v-deep h1 {
  font-weight: bold;
  padding-top: 60px;
}

::v-deep h2 {
  font-weight: bold;
  width: 100%;
  border-bottom: 3px solid #8fc31f;
  padding-top: 60px;
  padding-bottom: 2px;
  padding-left: 6px;
  line-height: 1.2;
}

::v-deep h3 {
  display: block;
  margin-top: 1em;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  line-height: 1.2;
  padding: 0 0.5em;
  background: transparent;
  border-left: 0.25em solid #8fc31f;
}

::v-deep figure.wp-block-video video {
  height: auto;
  width: 100%;
  max-width: 320px;
}

::v-deep h4 {
  font-weight: bold;
  padding-top: 12px;
}

::v-deep p {
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: normal;
}

::v-deep nav {
  padding: 16px;
  border: 0.1em solid #b6b6b6;
}

::v-deep strong {
  font-weight: bold;
  font-size: 1em;
  /* border-bottom: 2px solid red; */
  /* background: linear-gradient(transparent 60%, #ff9 60%); */
}

::v-deep figcaption {
  font-size: 0.6em;
  color: rgb(85, 85, 85);
}

::v-deep ul {
  /* list-style-type: decimal; */
  padding: 0.5em;
}

::v-deep li {
  margin: 8px 0;
}

::v-deep a:link {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}

::v-deep .wp-block-button {
  border-radius: 8px;
  padding: 1em;
  background-color: #8fc31f;
  text-align: center;
  text-decoration: none;
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
}

::v-deep .wp-block-button__link {
  color: white;
  font-size: 1.15em;
  font-weight: bold;
}

::v-deep table {
  padding: 1em 20px;
  text-align: left;
}

::v-deep mark {
  font-weight: bold;
  font-size: 1.15em;
  background: linear-gradient(transparent 60%, #ff9 60%);
}

::v-deep table {
  border-collapse: separate;
  /* separate,collapse */
}

::v-deep th {
  background-color: rgba(200, 200, 200);
  font-weight: bold;
  vertical-align: text-center;
  font-size: 0.9em;
  /* font-family: Arial; */
  padding: 10px;
  width: 20em;
}

::v-deep td {
  background-color: rgb(250, 250, 250);
  vertical-align: text-top;
  font-size: 0.8em;
  /* font-family: Arial; */
  padding: 10px;
}
</style>