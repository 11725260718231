<template>
  <div v-if="!showAllItem">
    <v-card
      class="event-card mb-4"
      height="140"
      @click="cardOnClick()"
      v-if="crowdfundingsObjects.crowdfunding_items"
    >
      <v-layout row>
        <v-img
          width="140"
          max-width="140"
          cover
          :lazy-src="local_logo"
          aspect-ratio="1"
          :src="
            getImageUrl(crowdfundingsObjects.crowdfunding_items[0].picture_path)
          "
        >
          <div class="date-ribbon">
            <p class="text-body-2" font-weight: bold>OFF</p>
            <p class="text-body-1" style="font-weight: bold">
              {{
                crowdfundingsObjects.crowdfunding_items[0].discount_rate.toFixed(
                  0
                )
              }}%
            </p>
          </div>
        </v-img>

        <v-layout column justify-space-between class="flex-grow-1">
          <v-row class="mx-0 mt-n4">
            <v-col cols="12">
              <div
                class="text-body-2 mt-2"
                v-snip="{ lines: 1 }"
                style="color: rgb(51, 51, 51); font-weight: bold"
              >
                {{ this.crowdfundingsObjects.title }}
              </div>

              <p class="text-caption text-grey mt-0">
                {{ crowdfundingsObjects.shop.name }}
              </p>
              <p class="text-caption text-grey mt-n1">
                {{
                  prefectureCodeToString(crowdfundingsObjects.shop.prefecture)
                }}
              </p>

              <div
                v-if="
                  crowdfundingsObjects.crowdfunding_items[index].percentage ==
                  null
                "
              >
                <div
                  v-if="
                    crowdfundingsObjects.crowdfunding_items[index]
                      .discount_pattern == 0
                  "
                >
                  <p class="mt-0 text-body-2" style="color: rgb(160, 160, 160)">
                    <span class="text-h6" style="color: #fe453e">
                      <strong
                        >RM{{
                          this.crowdfundingsObjects.crowdfunding_items[index]
                            .price
                        }}
                      </strong>
                    </span>
                    &nbsp;
                    <strike>
                      RM{{
                        this.crowdfundingsObjects.crowdfunding_items[index]
                          .original_price
                      }}----
                    </strike>
                  </p>
                </div>
                <div v-else>
                  <div class="text-h6 text-red" style="font-weight: bold">
                    {{ $t("crowdfunding.all_menu") }}
                  </div>
                </div>
              </div>

              <div v-else>
                <p class="text-h6" style="color: #fe453e">
                  <strong
                    >{{
                      crowdfundingsObjects.crowdfunding_items[index].percentage
                    }}% OFF
                  </strong>
                </p>
              </div>

              <p class="text-body-2">
                {{ this.crowdfundingsObjects.crowdfunding_items[0].name }}
              </p>

              <p class="text-body-2" style="font-weight: normal">
                {{ $t("crowdfunding.available_voucher") }}
                {{ this.crowdfundingsObjects.crowdfunding_items[0].amount }}
              </p>
            </v-col>
          </v-row>
        </v-layout>
      </v-layout>
    </v-card>
  </div>

  <!-- Use At Shopdetail -->
  <div v-else>
    <div v-if="crowdfundingsObjects.crowdfunding_items">
      <v-card
        class="event-card mb-4"
        height="140"
        @click="cardOnClick()"
        v-for="(item, index) in crowdfundingsObjects.crowdfunding_items.slice(
          0,
          this.limit
        )"
        :key="index"
      >
        <v-layout row>
          <v-img
            width="140"
            max-width="140"
            cover
            :lazy-src="local_logo"
            aspect-ratio="1"
            :src="getImageUrl(item.picture_path)"
          >
            <div
              v-if="
                crowdfundingsObjects.crowdfunding_items[index].discount_rate > 0
              "
              class="date-ribbon"
            >
              <p class="text-body-2" font-weight: bold>OFF</p>
              <p class="text-body-1" style="font-weight: bold">
                {{
                  crowdfundingsObjects.crowdfunding_items[
                    index
                  ].discount_rate.toFixed(0)
                }}%
              </p>
            </div>
          </v-img>

          <v-layout column justify-space-between class="flex-grow-1">
            <v-row class="mx-0 mt-n4">
              <v-col cols="12">
                <div
                  class="text-body-1 mt-2"
                  v-snip="{ lines: 1 }"
                  style="color: rgb(51, 51, 51); font-weight: bold"
                >
                  {{ crowdfundingsObjects.crowdfunding_items[index].name }}
                </div>

                <p class="text-caption text-grey mt-0">
                  {{ crowdfundingsObjects.shop.name }}
                </p>

                <p class="text-caption text-grey mt-n1">
                  {{ getShopPrefectureTitle(crowdfundingsObjects.shop) }}
                </p>

                <div
                  v-if="
                    crowdfundingsObjects.crowdfunding_items[index]
                      .discount_pattern == 0
                  "
                >
                  <p class="mt-0 text-body-2" style="color: rgb(160, 160, 160)">
                    <span class="text-h6" style="color: #fe453e">
                      <strong
                        >RM{{
                          this.crowdfundingsObjects.crowdfunding_items[index]
                            .price
                        }}
                      </strong>
                    </span>
                    &nbsp;
                    <strike
                      v-if="
                        crowdfundingsObjects.crowdfunding_items[index]
                          .discount_rate > 0
                      "
                    >
                      RM{{
                        this.crowdfundingsObjects.crowdfunding_items[index]
                          .original_price
                      }}
                    </strike>
                  </p>
                </div>
                <div v-else>
                  <div class="text-h6 text-red" style="font-weight: bold">
                    {{ $t("crowdfunding.all_menu") }}
                  </div>
                </div>
                <!-- <p class="text-body-2" style="font-weight: normal">
                  {{ $t("crowdfunding.available_voucher") }}
                  {{ this.crowdfundingsObjects.crowdfunding_items[index].amount }}
                </p> -->

                <div
                  v-snip="{ lines: 2 }"
                  style="font-weight: normal"
                  class="text-body-2"
                >
                  {{
                    this.crowdfundingsObjects.crowdfunding_items[index]
                      .description
                  }}
                </div>
              </v-col>
            </v-row>
          </v-layout>
        </v-layout>
      </v-card>

      <!-- <v-row v-if="crowdfundingsObjects.crowdfunding_items.length > limit">
        <v-col class="text-center">
          <v-btn
            width="40%"
            elevation="0"
            class="ma-6 border"
            height="36px"
            size="small"
            :ripple="false"
            rounded="pill"
            @click="showMoreOnClick"
          >
            {{ $t("homeview.show_more") }}
          </v-btn>
        </v-col>
      </v-row> -->
    </div>
  </div>
</template>

<script>
import Uitls from "@/uitls/Uitls.js";
import Constants from "@/uitls/Constants";

export default {
  props: {
    crowdfundingsObjects: {
      type: Object,
      default: () => ({}),
    },
    showAllItem: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  setup(props) {
    // setup() receives props as the first argument.
    // console.log("showAllItem: " +props.showAllItem);
  },

  data() {
    return {
      local_logo: require("@/assets/img/logo120.png"),
      limit: 4,
    };
  },

  methods: {
    cardOnClick() {
      this.$router.push({
        name: "CrowdfundingsDetail",
        params: { crowdfundingId: this.crowdfundingsObjects.id },
      });
    },

    getShopPrefectureTitle(value) {
      const city_id = value.city_id;
      const prefecture_v2 = value.prefecture;

      // if (city_id != null) {
      //   const cityList = JSON.parse(JSON.stringify(targetPrefecture.cityList));

      //   return (
      //     this.$i18n.t(Uitls.prefectureCodeToString(prefecture_v2)) +
      //     ", " +
      //     this.$i18n.t("city." + city_id)
      //   );
      // } else {
        return this.$i18n.t(Uitls.prefectureCodeToString(prefecture_v2));
      // }
    },

    getImageUrl(imagePath) {
      return new URL(Constants.IMAGE_URL + imagePath).href;
    },

    showMoreOnClick() {
      this.limit = this.crowdfundingsObjects.crowdfunding_items.length;
    },
  },
};
</script>

<style scoped>
/* https://noahbres.github.io/blog/bonfire-devlog-3-how-to-make-an-events-card-list */
.event-card .desc {
  margin-bottom: 1.2em;
  font-size: 0.16em;
  padding-left: 0.1em;
}

.event-card .date-ribbon {
  position: absolute;
  top: 0px;
  left: 1em;
  background: #fe453e;
  color: #fff;
  padding: 0em 0.3em;
  padding-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.event-card .date-ribbon::before,
.event-card .date-ribbon::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0%;
  height: 0px;
}

.event-card .date-ribbon::before {
  left: 0;
  border-left: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-right: solid 2em transparent;
}

.event-card .date-ribbon::after {
  right: 0;
  border-right: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-left: solid 2em transparent;
}
</style>
