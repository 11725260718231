<template>
  <v-sheet class="pa-0 ma-0" color="white">
    <v-list-item class="">
      <template v-slot:prepend>
        <v-img src="/magazine.png" :width="26" />
      </template>
      <p class="text-h6 mx-2 mt-0" style="font-weight: bold">
        {{ shopName }} {{ $t("homeview.relative_topics") }}
      </p>
    </v-list-item>

    <v-container>
      <v-row class="mx-3">
        <v-col
          v-for="item in articleObjects"
          :key="item.id"
          cols="12"
          md="6"
          xs="12"
          sm="12"
        >
          <v-card
            height="140"
            :ripple="false"
            @click="articleDetailOnClick(item)"
          >
            <v-layout row>
              <v-img
                width="140"
                max-width="140"
                cover
                :src="item.thumbnail ? item.thumbnail : no_IMAGE"
                :lazy-src="no_IMAGE"
                aspect-ratio="1"
              />
              <v-layout column justify-space-between class="flex-grow-1">
                <v-row class="mx-0 mt-n4">
                  <v-col cols="12">
                    <div
                      class="text-body-1 mt-6"
                      v-snip="{ lines: 4 }"
                      style="color: rgb(51, 51, 51)"
                    >
                      <p v-if="this.$i18n.locale == 'zhCN'">
                        {{ item.title_cn }}
                      </p>
                      <p v-else-if="this.$i18n.locale == 'ms'">
                        {{ item.title_my }}
                      </p>
                      <p v-else>
                        {{ item.title_en }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-layout>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import Constants from "@/uitls/Constants";

export default {
  props: {
    articleObjects: {
      type: Object,
      default: () => ({}),
    },
    shopName: {
      type: String,
      default: () => ({}),
    },
  },
  setup() {},

  data() {
    return {
      no_IMAGE: Constants.NO_IMAGE,
    };
  },

  methods: {
    articleDetailOnClick(item) {
      let articleID = "";
      if (this.$i18n.locale == "zhCN") {
        articleID = item.url_cn;
      } else if (this.$i18n.locale == "ms") {
        articleID = item.url_my;
      } else {
        articleID = item.url_en;
      }

      this.$emit("articleIDCallBack", articleID);
    },

    setArticleTitle() {},
  },
};
</script>

<style scoped>
.bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0;
  padding: 0.3em; /* Adjust as needed */
}
</style>