import { createRouter, createWebHistory } from 'vue-router'
import firebase from '@/plugins/firebase'
import HomeView from '../views/HomeView.vue'
import BusinessPage from '../views/BusinessPage.vue'
import NotFoundView from '../views/NotFoundView.vue'
import Shop from '../views/Shop.vue'
import ShopBySlugV2 from '../views/ShopBySlugV2.vue'
import ShopBySlug from '../views/ShopBySlug.vue'
import SearchMap from '../views/SearchMap.vue'
import SearchVoucherResult from '../views/SearchVoucherResult.vue'
import ReservationForm from '../views/ReservationForm.vue'
import ReservationFormV2 from '../views/ReservationFormV2.vue'
import ReservationFormV2BySlug from '../views/ReservationFormV2BySlug.vue'
import SearchCategory from '../views/SearchCategory.vue'
import SearchResult from '../views/SearchResult.vue'
import ReservationSubmitCompleted from '../views/ReservationSubmitCompleted.vue'
import ArticleViewList from '../views/Article/ArticleViewList.vue'
import ArticleViewDetail from '../views/Article/ArticleViewDetail.vue'
import ArticleViewDetailBySlug from '../views/Article/ArticleViewDetailBySlug.vue'
import ArticleSearch from '../views/Article/ArticleSearch.vue'
import MyPage from '../views/MyPage/MyPage.vue'
import FavoritePage from '../views/MyPage/FavoritePage.vue'
import SettingPage from '../views/MyPage/SettingPage.vue'
import SchedulePage from '../views/MyPage/SchedulePage.vue'
import SearchFilter from '../views/SearchFilter.vue'
import CrowdfundingsDetail from '../views/Crowdfundings/CrowdfundingsDetail.vue'
import CrowdfundingsCheckout from '../views/Crowdfundings/CrowdfundingsCheckout.vue'
import CrowdfundingsCheckoutV2 from '../views/Crowdfundings/CrowdfundingsCheckoutV2.vue'
import LoginPage from '../views/LoginPage.vue'
import LoginPageWithGuest from '../views/LoginPageWithGuest.vue'
import ThanksPage from '../views/ThanksPage.vue'
import PurchaseCompleted from '../views/PurchaseCompleted.vue'
import SNSShareDialog from '../components/SNSShareDialog.vue'
import ShopCardWithAI from '../components/ShopCardWithAI.vue'
import CrowdfundingsCard from "@/components/CrowdfundingsCard.vue";
import ResetPassword from '../views/SignUpUser/ResetPassword.vue'
import VerificationEmail from '../views/SignUpUser/VerificationEmail.vue'
import EmailSetting from '../views/MyPage/EmailSetting.vue'
import PasswordSetting from '../views/MyPage/PasswordSetting.vue'
import ProfileSetting from '../views/MyPage/ProfileSetting.vue'
import ContactSetting from '../views/MyPage/ContactSetting.vue'
import TroubleSetting from '../views/MyPage/TroubleSetting.vue'
import InterestSetting from '../views/MyPage/InterestSetting.vue'
import NewsLetterSetting from '../views/MyPage/NewsLetterSetting.vue'
import PurchaseHistory from '../views/MyPage/PurchaseHistory.vue'
import PurchaseDetail from '../views/MyPage/PurchaseDetail.vue'
import BookingPage from '../views/MyPage/BookingPage.vue'
import BookingDetails from '../views/MyPage/BookingDetails.vue'
import PrivacyPolicy from '../views/TermPolicy/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermPolicy/TermsAndConditions.vue'
import GMapView from '../views/GMapView.vue'
import ModelsPhotoListView from '../views/ModelsPhotoListView.vue'
import ShopRegister from '../views/ShopRegister/ShopRegister.vue'
import ShopRegisterName from '../views/ShopRegister/ShopRegisterName.vue'
import ContactUsGform from '../views/ContactUs/ContactUsGform.vue'
import ContactUs from '../views/ContactUs/ContactUs.vue'
import ContactUsThanksPage from '../views/ContactUs/ContactUsThanksPage.vue'
import ShopRegisterDetails from '../views/ShopRegister/ShopRegisterDetails.vue'
import UseVoucher from '../components/UseVoucher.vue'
import AreaView from '../views/AreaView.vue'
import ForShopLanguageSelect from '../views/Survey/ForShopLanguageSelect.vue'
import VoucherHistoryPage from '../views/MyPage/VoucherHistoryPage.vue'
import VoucherHistoryDetailPage from '../views/MyPage/VoucherHistoryDetailPage.vue'
import SponsorBanner from '@/components/Banner/SponsorBanner.vue'
import SelectArea from '../views/SelectArea.vue'

import PrefectureCityShopList from '../views/PrefectureCityShopList.vue'

import axiosClient from '@/plugins/axiosClient';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { AUTH_API } from '@/store/api'

const userData = {
  'name': 'username'
}

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    exact: true,
    meta: {
      breadCrumb: [
        {
          name: 'mypage.topPage'
        }
      ]
    },
  },
  {
    path: '/hairsalon/:prefecture?/:city?',
    name: 'PrefectureCityShopList',
    component: PrefectureCityShopList,
    props: true,
  },
  {
    path: '/topic/:article_slug',
    name: 'ArticleViewDetailBySlug',
    component: ArticleViewDetailBySlug,
    props: true,
  },




  {
    path: '/businesspage',
    name: 'BusinessPage',
    component: BusinessPage,
    meta: {
      hideNavbar: true,
      hideFotbar: false,
    }
  },
  {
    path: '/shop/:id',
    name: 'Shop',
    component: Shop,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }
  },
  {
    path: '/:shop_slug',
    name: 'ShopBySlugV2',
    component: ShopBySlugV2,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/searchmap/:address',
    name: 'SearchMap',
    component: SearchMap,
    props: true
  },
  {
    path: '/shop/:id/reserve',
    name: 'ReservationForm',
    component: ReservationFormV2,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/reserve_completed',
    name: 'ReservationSubmitCompleted',
    component: ReservationSubmitCompleted,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  // {
  //   path: '/hairsalon/category',
  //   name: 'SearchCategory',
  //   component: SearchCategory,
  //   props: true
  // },
  {
    path: '/SponsorBanner',
    name: 'SponsorBanner',
    component: SponsorBanner,
    props: true
  },
  {
    path: '/topic',
    name: 'ArticleViewList',
    component: ArticleViewList,
    props: true,
    exact: true,
    meta: {
      breadCrumb: [
        { name: 'mypage.topPage', to: '/', disabled: false },
        { name: 'homeview.new_topics' }
      ]
    }
  },
  {
    path: '/topic/:id',
    name: 'ArticleViewDetail',
    component: ArticleViewDetail,
    props: true,
    exact: true,
    beforeEnter: (to, from, next) => {
      to.meta.breadCrumb[2].name = `${to.params.id}`
      next();
    },
    meta: {
      breadCrumb: [
        { name: 'mypage.topPage', to: '/' },
        { name: 'homeview.new_topics', to: 'list' },
        { name: 'ArticleViewDetail' },
      ]
    },
  },
  {
    path: '/hairsalon/topic/find/:keyword',
    name: 'ArticleSearch',
    component: ArticleSearch,
    props: true
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: MyPage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/selectarea',
    name: 'SelectArea',
    component: SelectArea,
    props: true
  },
  {
    path: '/searchvoucher',
    name: 'SearchVoucherResult',
    component: SearchVoucherResult,
    props: true,
  },
  {
    path: '/emailsetting',
    name: 'EmailSetting',
    component: EmailSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/passwordsetting',
    name: 'PasswordSetting',
    component: PasswordSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/profilesetting',
    name: 'ProfileSetting',
    component: ProfileSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/contactsetting',
    name: 'ContactSetting',
    component: ContactSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/troublesetting:isNewUser',
    name: 'TroubleSetting',
    component: TroubleSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/interestsetting:isNewUser',
    name: 'InterestSetting',
    component: InterestSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/newslettersetting',
    name: 'NewsLetterSetting',
    component: NewsLetterSetting,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/mypage/favoritepage',
    name: 'FavoritePage',
    component: FavoritePage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/purchasehistory',
    name: 'PurchaseHistory',
    component: PurchaseHistory,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    //PurchaseDetail
    path: '/purchasedetail/:id',
    name: 'PurchaseDetail',
    component: PurchaseDetail,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bookingpage',
    name: 'BookingPage',
    component: BookingPage,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/bookingdetails/:id',
    name: 'BookingDetails',
    component: BookingDetails,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/settingpage',
    name: 'SettingPage',
    component: SettingPage,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/voucher-history',
    name: 'VoucherHistoryPage',
    component: VoucherHistoryPage,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/voucher-history/:id',
    name: 'VoucherHistoryDetailPage',
    component: VoucherHistoryDetailPage,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/schedulepage',
    name: 'SchedulePage',
    component: SchedulePage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shopcardwithai',
    name: 'ShopCardWithAI',
    component: ShopCardWithAI,
    props: true
  },
  {
    path: '/crowdfundingscard',
    name: 'CrowdfundingsCard',
    component: CrowdfundingsCard,
    props: true
  },
  // {
  //   path: '/hairsalon/searchfilter',
  //   name: 'SearchFilter',
  //   component: SearchFilter,
  //   props: true,
  //   meta: {
  //     hideNavbar: true,
  //     hideFotbar: true,
  //   }
  // },
  {
    path: '/hairsalon/searchresult',
    name: 'SearchResult',
    component: SearchResult,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }

  },
  {
    path: '/snssharedialog',
    name: 'SNSShareDialog',
    component: SNSShareDialog,
    props: true
  },
  {
    path: '/voucher/:crowdfundingId',
    name: 'CrowdfundingsDetail',
    component: CrowdfundingsDetail,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }
  },
  {
    path: '/loginpage',
    name: 'LoginPage',
    component: LoginPage,
    props: true,
  },
  {
    path: '/loginpagewithguest',
    name: 'LoginPageWithGuest',
    component: LoginPageWithGuest,
    props: true,
  },
  {
    path: '/crowdfundingscheckout:crowdfundingId:crowdfundingItemId',
    name: 'CrowdfundingsCheckout',
    component: CrowdfundingsCheckoutV2,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
      requiresAuth: true
    }
  },

  {
    path: '/ThanksPage',
    name: 'ThanksPage',
    component: ThanksPage,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },

  {
    path: '/purchasecompleted',
    name: 'PurchaseCompleted',
    component: PurchaseCompleted,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/verificationemail:user_email',
    name: 'VerificationEmail',
    component: VerificationEmail,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: false,
    }
  },
  {
    path: '/termsconditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: false,
    }
  },
  {
    path: '/map',
    name: 'GMapView',
    component: GMapView,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }
  },
  {
    path: '/hairsalon/category/models/:gender/:hairtype',
    name: 'ModelsPhotoListView',
    component: ModelsPhotoListView,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }
  },
  {
    path: '/shopregister',
    name: 'ShopRegister',
    component: ShopRegister,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/shopregistername',
    name: 'ShopRegisterName',
    component: ShopRegisterName,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/shopregisterdetails',
    name: 'ShopRegisterDetails',
    component: ShopRegisterDetails,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/contactusgform',
    name: 'ContactUsGform',
    component: ContactUsGform,
    props: true,
    meta: {
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/contactus/:type',
    name: 'ContactUs',
    component: ContactUs,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: false,
    }
  },
  {
    path: '/contactus/done',
    name: 'ContactUsThanksPage',
    component: ContactUsThanksPage,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: false,
    }
  },
  {
    path: '/voucher/detail/:crowdfundingItemId/:shopId',
    name: 'UseVoucher',
    component: UseVoucher,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/area/:area',
    name: 'AreaView',
    component: AreaView,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: false,
    }
  },
  {
    path: '/survey',
    name: 'ForShopLanguageSelect',
    component: ForShopLanguageSelect,
    props: true,
    meta: {
      hideNavbar: false,
      hideFotbar: true,
    }
  },
  {
    path: '/:shop_slug/review',
    name: 'ShopBySlug',
    component: ShopBySlug,
    props: true,
  },
  {
    path: '/:shop_slug/pricelist',
    name: 'ShopBySlug',
    component: ShopBySlug,
    props: true,
  },
  {
    path: '/:shop_slug/stylist',
    name: 'ShopBySlug',
    component: ShopBySlug,
    props: true,
  },
  {
    path: '/:shop_slug/gallery',
    name: 'ShopBySlug',
    component: ShopBySlug,
    props: true,
  },
  {
    path: '/:shop_slug/reserve',
    name: 'ReservationFormV2BySlug',
    component: ReservationFormV2BySlug,
    props: true,
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFotbar: true,
    }
  },
  {
    path: '/:shop_slug',
    name: 'ShopBySlug',
    component: ShopBySlug,
    props: true,
  },
  //catchall 404
  {
    path: '/:catchAll(.*)',
    name: 'NotFoundView',
    component: NotFoundView
  },
]

// const scrollBehavior = (to, from, savedPosition) => {
//   console.log("router savedPosition: %o", savedPosition);
//   if (savedPosition) {
//     savedPosition.top;
//     return savedPosition
//   } else {
//     return { top: 0 }
//   }
// };

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(savedPosition)
      }, 1500);

    })
  } else {
    return { x: 0, y: 0 }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes,
  mode: 'history',
  scrollBehavior,

});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(async function (user) {

    if (user && !cookies.get('userToken')) {
      const firebaseToken = await user.getIdToken();
      const params = {
        id_token: firebaseToken,
      };

      const response = await axiosClient
        .post(AUTH_API, params, {
          Authorization: "Bearer " + firebaseToken,
        })

      cookies.set('userToken', response.data.token)
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!user) {
        next({
          name: 'LoginPage',
          query: { redirect: to.fullPath }
        });
      } else {
        next();
      }
    }
    else if (to.matched.some(record => record.meta.requiresAuthOrGuest)) {
      if (!user) {
        next({
          name: 'LoginPageWithGuest',
          query: { redirect: to.fullPath }
        });
      } else {
        next();
      }
    }
    else {
      next();
    }
  });
});

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


// trackRouter(router);

export default router;
