<template>
  <v-sheet border rounded class="pa-2">
    <v-layout row>
      <v-img
        class="ma-1"
        width="120"
        max-width="120"
        :lazy-src="local_logo"
        aspect-ratio="1"
        :src="getImageUrl(item.picture_path)"
      />

      <v-layout column justify-space-between class="flex-grow-1">
        <v-row class="mt-n4 mx-0">
          <v-col cols="12">
            <v-chip
              size="small"
              class="mt-2"
              color="red"
              text-color="white"
              v-if="calculate() > 0"
            >
              <p class="text-body-1">{{ calculate() }}% OFF</p>
            </v-chip>

            <div v-if="item.discount_pattern == 0">
              <div class="text-h6 text-red" style="font-weight: bold">
                RM
                {{ item.price }}
                <strike class="text-grey text-h6 mx-1" v-if="calculate() > 0">
                  RM
                  {{ item.original_price }}
                </strike>
              </div>
            </div>
            <div v-else>
              <div class="text-h6 text-red" style="font-weight: bold">  {{ $t("crowdfunding.all_menu") }}</div>
            </div>

            <p
              class="text-body-2 mt-1"
              style="font-weight: bold"
              v-if="showTicketCount"
            >
              {{ $t("crowdfunding.available_voucher") }}
            </p>
            <p
              class="text-h5"
              style="font-weight: lighter"
              v-if="showTicketCount"
            >
              {{ item.amount }}
            </p>
          </v-col>
        </v-row>
      </v-layout>
    </v-layout>

    <v-container>
      <p class="text-body-1" style="font-weight: bold">
        {{ item.name }}
      </p>
      <p
        class="text-caption text-black mt-1"
        v-html="replaceText(item.description)"
      ></p>
    </v-container>

    <v-sheet justify="center" align="center">
      <v-btn
        v-if="showAllItem"
        :ripple="false"
        class="mt-2 mb-2"
        size="x-large"
        rounded="lg"
        elevation="2"
        flat
        color="green"
        height="60"
        block
        :disabled="isUsed"
        @click="$emit('voucherCellCallBack', item)"
      >
        <strong>
          {{
            $t(isUsed ? "crowdfunding.usedVoucher" : "crowdfunding.getVoucher")
          }}
        </strong>
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>

<script>
import Constants from "@/uitls/Constants";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isUsed: {
      type: Boolean,
      default: false,
    },
    showAllItem: {
      type: Boolean,
      default: true,
      required: false,
    },
    showTicketCount: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data() {
    return {
      local_logo: require("@/assets/img/logo120.png"),
    };
  },

  methods: {
    getImageUrl(imagePath) {
      return new URL(Constants.IMAGE_URL + imagePath).href;
    },

    replaceText(text) {
      var replaceText = "";
      try {
        replaceText = text.replace(/\n/g, "<br />");
      } catch (e) {
        replaceText = "";
      }
      return replaceText;
    },

    calculate() {
      return ((1 - this.item.price / this.item.original_price) * 100).toFixed(
        0
      );
    },
  },
};
</script>


<style scoped></style>