<template>
  <v-toolbar color="black" flat app height="48">
    <v-toolbar-title>
      <v-img
        alt="Beauty360 Business"
        v-bind:src="logo_src"
        height="30"
        @click="logoClick"
        aspect-ratio="1"
      ></v-img
    ></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      class="text-none"
      variant="outlined"
      rounded="s"
      size="small"
      color="white"
      :ripple="false"
      @click="joinOnClick"
    >
      <p style="font-weight: normal">{{ $t("busienssPage.joinFree") }}</p>
    </v-btn>
    <v-btn
      class="text-none mx-4"
      variant="text"
      rounded="xl"
      size="small"
      color="white"
      :ripple="false"
      @click="adminLogin"
    >
      <p style="font-weight: normal">{{ $t("loginpage.signin") }}</p>
    </v-btn>

    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn icon="mdi-translate" v-bind="props" :ripple="false"></v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="lang in languages"
          :key="lang.title"
          @click="changeLanguage(lang.language)"
        >
          <v-list-item-title>{{ lang.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
  <VSonner position="top-center" />
</template>

<script>
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: {
    VSonner,
  },

  data() {
    return {
      logo_src: require("@/assets/newlogo.png"),
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "cn", language: "zhCN", title: "中文" },
        { flag: "ms", language: "ms", title: "Melayu" },
      ],
    };
  },

  methods: {
    joinOnClick() {
      this.$router.push({
        name: "ShopRegister",
      });
    },

    logoClick() {
      this.$router.push({ name: "HomeView" });
    },

    changeLanguage(newLang) {
      this.$i18n.locale = newLang;
      window.location.reload();
    },

    adminLogin() {
      const url = new URL("https://backyard.beauty-360.com/");
      window.open(url.toString(), "_blank");
    },
  },
};
</script>