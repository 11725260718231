import i18n from '@/uitls/Translations'
import moment from "moment";

const utils = {

  /**
   * isEmpty
   * @param {value, array, string, object} value
   */
  isEmpty(value) {
    return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    )
  },


  removeArraySymbleForKeyword(string) {

    let newString = string
      .replace("[", "")
      .replace("]", "")
      .replaceAll('"', "");

    return newString;
  },

  setShopDetails(shop, shop_img_small) {

    let opening_time = new Date(Date.parse("Thu, 01 Jan 1970 " + shop.opening_time));
    let closing_time = new Date(Date.parse("Thu, 01 Jan 1970 " + shop.closing_time));

    const shopPrice = this.calculateShopPriceRange(shop)

    return {
      shop_id: shop.id,
      shop_title: shop.name,
      shop_img_small: shop_img_small,
      shop_description: shop.detail.description,
      shop_description_zhcn: shop.detail.description_zhcn,
      ai_review_short: shop.detail.ai_review_short,
      ai_review_short_zhcn: shop.detail.ai_review_short_zhcn,
      ai_review_short_ms: shop.detail.ai_review_short_ms,
      shop_location: shop.detail.station,
      shop_prefecture: shop.prefecture,
      shop_meter: shop.shop_meter,
      shop_opening_times: opening_time ? this.formatAMPM(opening_time) : "-",
      shop_closing_times: closing_time ? this.formatAMPM(closing_time) : "-",
      shop_price: shopPrice,
      shop_img_list: shop.pictures,
      shop_meter: shop.meter * 0.001,
      shop_crowdfundings: shop.crowdfundings,
      shop_lat: shop.latitude,
      shop_lng: shop.longitude,
      os_rating: shop.detail.os_rating,
      os_reviews: shop.detail.os_reviews,
      shop_favorite_count: shop.favorite_count,
      prefecture_v2: shop.prefecture_v2,
      shop_is_official: shop.is_official,
      shop_city: shop.city ? shop.city : "",
      shop_slug: shop.shop_slug ? shop.shop_slug : "",
      shop_menus: shop.menus,
      shop_tags: shop.tags ? shop.tags : [] ,
    }
  },


  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  },

  isNil(data) {
    return data === null || data === undefined
  },
  cloneDeep(data) {
    return JSON.parse(JSON.stringify(data))
  },

  businessTypeTags() {
    return [
      {
        title: "Hair Salon",
        languageTitle: "hair_salon",
        img: require("@/assets/img/salon.png"),
      },
      {
        title: "Beauty Salon",
        languageTitle: "beauty_salon",
        img: require("@/assets/img/beauty.png"),
      },
      {
        title: "Nail Salon",
        languageTitle: "nail_salon",
        img: require("@/assets/img/nail.png"),
      },
      {
        title: "Baber Shop",
        languageTitle: "baber_salon",
        img: require("@/assets/img/baber.png"),
      },
      {
        title: "Massage & SPA",
        languageTitle: "massage_spa",
        img: require("@/assets/img/massage.png"),
      },
      {
        title: "GYM & Fitness",
        languageTitle: "gym_fit",
        img: require("@/assets/img/treadmill.png"),
      },
      {
        title: "Personal Trainer",
        languageTitle: "personal",
        img: require("@/assets/img/coach.png"),
      },
      {
        title: "Therapy Center",
        languageTitle: "therapy",
        img: require("@/assets/img/therapy.png"),
      },
      {
        title: "Tatoo & Piercing",
        languageTitle: "tatoo",
        img: require("@/assets/img/tatoo.png"),
      },
      {
        title: "Tanning Studio",
        languageTitle: "tanning",
        img: require("@/assets/img/chair.png"),
      },
      {
        title: "Other",
        languageTitle: "other",
        img: require("@/assets/img/shop.png"),
      },
    ];
  },

  timeList() {
    return [
      "8:00am",
      "9:00am",
      "10:00am",
      "11:00am",
      "12:00am",
      "1:00pm",
      "2:00pm",
      "3:00pm",
      "4:00pm",
      "5:00pm",
      "6:00pm",
      "7:00pm",
      "8:00pm",
      "9:00pm",
      "10:00pm",
      "11:00pm",
    ];
  },

  GPTSummaryTags() {
    return [
      "utls.korea",
      "utls.japanese",
      "utls.customer_satisfaction",
      "utls.quality",
      "utls.awards",
      "utls.friendly_staff",
      "utls.professional_service",
      "utls.affordable_prices",
      "utls.ambience",
      "utls.skilled_hairstylists",
      // "utls.high_recommend",
      "utls.comfortable_environment",
      "utls.muslim_friendly",
      "utls.loreal",
      "utls.shiseido",
      "utls.kerastase",
    ];
  },

  GPTSummaryTagGetKey(value) {
    let stringKey = "";

    if (value == "韩国" || value == "Korean" || value == "Korea") {
      stringKey = "utls.korea";
    }
    else if (value == "日本" || value == "Japanese" || value == "Jepun") {
      stringKey = "utls.japanese";
    }
    else if (value == "客户满意度" || value == "Customer Satisfaction" || value == "Kepuasan") {
      stringKey = "utls.customer_satisfaction";
    }
    else if (value == "优质" || value == "Top Quality" || value == "Berkualiti") {
      stringKey = "utls.quality";
    }
    else if (value == "得奖" || value == "Award-Winning" || value == "Pemenang Anugerah") {
      stringKey = "utls.awards";
    }
    else if (value == "友好的员工" || value == "Friendly Staff" || value == "Kakitangan yang Ramah") {
      stringKey = "utls.friendly_staff";
    }
    else if (value == "服务专业" || value == "Professional Service" || value == "Perkhidmatan Profesional") {
      stringKey = "utls.professional_service";
    }
    else if (value == "价格实惠" || value == "Affordable Prices" || value == "Harga Berpatutan") {
      stringKey = "utls.affordable_prices";
    }
    else if (value == "好氛围" || value == "Great Ambience" || value == "Suasana") {
      stringKey = "utls.ambience";
    }
    else if (value == "熟练的发型师" || value == "Skilled Hairstylists" || value == "Pakar Rambut") {
      stringKey = "utls.skilled_hairstylists";
    }
    else if (value == "高评价" || value == "High Reviews" || value == "Sangat Disyorkan") {
      stringKey = "utls.high_recommend";
    }
    else if (value == "舒适的环境" || value == "Comfortable Environment" || value == "Persekitaran yang selesa") {
      stringKey = "utls.comfortable_environment";
    }
    else if (value == "穆斯林友好" || value == "Muslim friendly" || value == "mesra Muslim") {
      stringKey = "utls.muslim_friendly";
    }
    else if (value == "Loreal" || value == "巴黎欧莱雅(Loreal)") {
      stringKey = "utls.loreal";
    }
    else if (value == "Shiseido" || value == "资生堂(Shiseido)") {
      stringKey = "utls.shiseido";
    }
    else if (value == "Kérastase" || value == "巴黎卡詩(Kérastase)") {
      stringKey = "utls.kerastase";
    }

    return stringKey;
  },

  locationTags() {
    return [
      // { title: "prefecture.all_area", value: 0, img: require("@/assets/img/allarea.jpg"), desc: "areaview.all_area_desc" },
      { title: "prefecture.johor", value: 100, img: require("@/assets/img/jb.jpg"), desc: "areaview.countryPrefectureJohor" },
      { title: "prefecture.kedah", value: 101, img: require("@/assets/img/kedah.jpg"), desc: "areaview.countryPrefectureKedah" },
      { title: "prefecture.kelantan", value: 102, img: require("@/assets/img/kelantan.jpg"), desc: "areaview.countryPrefectureKelantan" },
      { title: "prefecture.melaka", value: 103, img: require("@/assets/img/melaka.jpg"), desc: "areaview.countryPrefectureMalacca" },
      { title: "prefecture.sembilan", value: 104, img: require("@/assets/img/sembilan.jpg"), desc: "areaview.countryPrefectureNegeriSembilan" },
      { title: "prefecture.pahang", value: 105, img: require("@/assets/img/pahang.jpg"), desc: "areaview.countryPrefecturePahang" },
      { title: "prefecture.pinang", value: 106, img: require("@/assets/img/pinang.jpg"), desc: "areaview.countryPrefecturePenang" },
      { title: "prefecture.perak", value: 107, img: require("@/assets/img/perak.jpg"), desc: "areaview.countryPrefecturePerak" },
      { title: "prefecture.perlis", value: 108, img: require("@/assets/img/perlis.jpg"), desc: "areaview.countryPrefecturePeris" },
      { title: "prefecture.selangor", value: 109, img: require("@/assets/img/selangor.jpg"), desc: "areaview.countryPrefectureSelangor" },
      { title: "prefecture.terengganu", value: 110, img: require("@/assets/img/terengganu.jpg"), desc: "areaview.countryPrefectureTerengganu" },
      { title: "prefecture.sabah", value: 111, img: require("@/assets/img/sabah.jpg"), desc: "areaview.countryPrefectureSabah" },
      { title: "prefecture.sarawak", value: 112, img: require("@/assets/img/sarawak.jpg"), desc: "areaview.countryPrefectureSarawak" },
      { title: "prefecture.kl", value: 113, img: require("@/assets/img/kl.jpg"), desc: "areaview.countryPrefectureKualaLumpur" },
      { title: "prefecture.labuan", value: 114, img: require("@/assets/img/labuan.jpeg"), desc: "areaview.countryPrefectureLabuan" },
      { title: "prefecture.putrajaya", value: 115, img: require("@/assets/img/putrajaya.jpg"), desc: "areaview.countryPrefecturePutrajaya" }
    ];
  },

  prefectureCodeToString(code) {
    switch (code) {
      case 100:
        return 'prefecture.johor'
      case 101:
        return 'prefecture.kedah'
      case 102:
        return 'prefecture.kelantan'
      case 103:
        return 'prefecture.melaka'
      case 104:
        return 'prefecture.sembilan'
      case 105:
        return 'prefecture.pahang'
      case 106:
        return 'prefecture.pinang'
      case 107:
        return 'prefecture.perak'
      case 108:
        return 'prefecture.perlis'
      case 109:
        return 'prefecture.selangor'
      case 110:
        return 'prefecture.terengganu'
      case 111:
        return 'prefecture.sabah'
      case 112:
        return 'prefecture.sarawak'
      case 113:
        return 'prefecture.kl'
      case 114:
        return 'prefecture.labuan'
      case 115:
        return 'prefecture.putrajaya'
      case 0:
        return 'prefecture.all_area'
      default:
        return 'prefecture.all_area'
    }
  },

  fireBaseErrorMessage(e, method = '') {
    switch (e.code) {
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
        return 'ログイン処理が途中で中断されました'
      case 'auth/invalid-custom-token':
        return '無効なカスタムトークンです'
      case 'auth/email-already-in-use':
        if (method.includes('signup')) {
          return 'このメールアドレスは使用されています'
        } else {
          return 'メールアドレスまたはパスワードが違います'
        }
      case 'auth/invalid-email':
        return 'メールアドレスの形式が正しくありません'
      case 'auth/user-disabled':
        return 'サービスの利用が停止されています'
      case 'auth/user-not-found':
        return 'メールアドレスまたはパスワードが違います'
      case 'auth/user-mismatch':
        if (method === 'signin/popup') {
          return '認証されているユーザーと異なるアカウントが選択されました'
        } else {
          return 'メールアドレスまたはパスワードが違います'
        }
      case 'auth/weak-password':
        return 'パスワードは6文字以上にしてください'
      case 'auth/wrong-password':
        return 'メールアドレスまたはパスワードが違います'
      case 'auth/popup-blocked':
        return '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください'
      case 'auth/operation-not-supported-in-this-environment':
      case 'auth/auth-domain-config-required':
      case 'auth/operation-not-allowed':
      case 'auth/unauthorized-domain':
        return '現在この認証方法はご利用頂けません'
      case 'auth/requires-recent-login':
        return '認証の有効期限が切れています'
      case 'auth/account-exists-with-different-credential':
        return 'すでに同じメールアドレスでアカウントが作成済みです。メールアドレスでログインしてください。'
      case 'account-exists-with-different-credential':
        return 'すでに同じメールアドレスでアカウントが作成済みです。メールアドレスでログインをお願いします。'
      default:
        if (method.includes('signin')) {
          return (
            '認証に失敗しました。しばらく時間をおき、ログイン情報を再度確認してお試しください。(エラーコード:' +
            e.code +
            ')'
          )
        } else {
          return (
            'エラーが発生しました。しばらく時間をおき、ログイン情報を再度確認してお試しください。(エラーコード:' +
            e.code +
            ')'
          )
        }
    }
  },

  categoryMappingResult(item, getArticleCategory) {
    if (getArticleCategory == null) {
      return item;
    }

    for (let index = 0; index < getArticleCategory.length; index++) {
      const element = getArticleCategory[index];
      if (element.id == item) {
        return element.name;
      }
    }
  },

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("YYYY-MM-DD");
    }
  },

  calculateShopPriceRange(shop) {
    let shopPrice = 'RM-'
    let minPrice = null
    let maxPrice = null
    if (shop && shop.active_menus && shop.active_menus.length > 0) {
      shop.active_menus.forEach(v => {
        if (v.amount && parseFloat(v.amount) > 0) {
          if (minPrice === null || v.amount < minPrice) {
            minPrice = v.amount;
          }
          if (maxPrice === null || v.amount > maxPrice) {
            maxPrice = v.amount;
          }
        }
      })
      if (minPrice && maxPrice) {
        if (minPrice !== maxPrice) {
          shopPrice = 'RM' + minPrice + '~RM' + maxPrice
        } else {
          shopPrice = 'RM' + maxPrice
        }
      }
    }
    return shopPrice
  },

  CONST: {
    RESERVATION: {
      STATUS: {
        REQUESTED: 'requested',
        RESERVED: 'reserved',
        CANCELED: 'canceled',
        RESERVE_CANCELED: 'reserve-canceled',
        VISITED: 'visited',
        REQUEST_EXPIRED: 'request-expired',
        RESERVE_EXPIRED: 'reserve-expired',
      },
      CANCELED_APPROVED: 'Canceled(APPROVED)',
    },

  },

  getShopPrefectureTitle(shopData) {
    let result = ''
    if (shopData) {
      if (shopData.prefecture_v2) {
        result = shopData.prefecture_v2.code ? ('prefecture.' + shopData.prefecture_v2.code) : shopData.prefecture_v2.name
      } else if (shopData.prefecture) {
        result = this.prefectureCodeToString(shopData.prefecture)
      }
    }
    return result
  },

}

export default utils